import { NavLink } from "react-router-dom";
//import './NavBar.css';


export default function NavBar(props){

    return <div id="nav">
        <div id="logo-txt">POWER MOUSE</div>
            <img src="../assets/icon-black128.png" id="logo-img"/>
            <ul>
                <li> <NavLink to="/en/" id="home">Home</NavLink> </li>
                <li> <NavLink to="/en/how%20to" id="howto">How to</NavLink> </li>
                <li> <NavLink to="/en/advance" id="advance">Advance</NavLink> </li>
                <li> <NavLink to="/en/premium" id="premium">Go Premium</NavLink> </li>
                <li> <NavLink to="/en/troubleshooting" id="troubleshooting">Troubleshooting</NavLink> </li>
                <li> <NavLink to="/en/contact" id="contact">Contact</NavLink> </li>
                <li id="user">
                    <div className="dropdown">
                        <button className="dropbtn">
                            {props.user ? <img src="../assets/loged.svg" id="loged" style={{margin:"0", height:"32px", width:"32px"}} />: <img src="../assets/unloged.svg" id="unloged" />}
                        </button>
                        <div className="dropdown-content">
                        {props.user ?
                            <>
                            <div id="greeting">Hello {props.user.displayName}</div>
                            <hr style={{margin:"0"}} />
                            <NavLink to="/Profile">Profile</NavLink>
                            <NavLink to="/plans">Plans</NavLink>
                            <a href="https://powermousex.com/billing.html">Billings</a>
                            <button id="log-out" onClick={props.logout}>Log out</button>
                            </>
                            :
                            <>
                            <NavLink to="/login">Login</NavLink>
                            <NavLink to="/sign up">Sign Up</NavLink>
                            </>
                            }
                        </div>
                    </div>
                </li>
            </ul>
    </div>
}
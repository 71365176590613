import { useEffect, useState } from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { updateProfile } from "firebase/auth";



export default function Profile(props){
    document.title = "Power Mouse X - Profile";

    
    const docRef = doc(props.db, "users", props.user.uid);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");

    useEffect(()=>{
        getDoc(docRef).then((docSnap) =>{
            if (docSnap.data()) {
                let fName = docSnap.data()["firstName"];
                let lName = docSnap.data()["lastName"];
                let userEmail = docSnap.data()["email"];
                setEmail(userEmail[0] +"..."+userEmail.slice(userEmail.indexOf("@")));
                setFirstName(fName.charAt(0).toUpperCase() + fName.slice(1));
                setLastName(lName.charAt(0).toUpperCase() + lName.slice(1));
            }
        });
    },[]);
    


    const updatePersonal = (event) => {
        event.preventDefault();
        updateProfile(props.user, {
            displayName: firstName
        }).then(()=>{
        setDoc(doc(props.db, "users", props.user.uid), {
            firstName: firstName,
            lastName: lastName,
        }, { merge: true });
        }).then(()=>{
          alert("Profile updated");  
        });
    }
    const updatePass = (event) => {
        event.preventDefault();
        
        
    }
    return (
        <div className="main-container">
            <table><tr>
                <td>
                <form id="personal-data-form" onSubmit={updatePersonal}>
                <div className="container">
                    {email}
                    <br />
                    <br />
                    <br />
                    <br />
                    <label htmlFor="first-name"><b>First name</b></label>
                    <input type="text" placeholder="Enter first name" id="personal-data-first-name" name="first-name" value={firstName} required onChange={(e) => setFirstName(e.target.value)}/>

                    <label htmlFor="last-name"><b>Last name</b></label>
                    <input type="text" placeholder="Enter last name" id="personal-data-last-name" name="last-name" value={lastName} required onChange={(e) => setLastName(e.target.value)} />

                    <div className="clearfix">
                        <button type="submit" id="profile-update-btn">
                        Update
                        </button>
                    </div>
                </div>
            </form>
                </td>
                {/*
                <td>
                <form id="change-pass-form" onSubmit={updatePass}>
                <div className="container">
                    <label htmlFor="old-pass"><b>Old password</b></label>
                    <input type="text" placeholder="Enter old password" id="change-pass-old" name="okd-pass" required onChange={(e) => setFirstName(e.target.value)}/>

                    <label htmlFor="new-pass"><b>New password</b></label>
                    <input type="text" placeholder="Enter new password" id="personal-data-name" name="last-name" required onChange={(e) => setLastName(e.target.value)} />

                    <label htmlFor="new-pass-repeat"><b>Repeat new password</b></label>
                    <input type="text" placeholder="Enter repeat new password" id="personal-data-name" name="last-name" required onChange={(e) => setLastName(e.target.value)} />

                    <div className="clearfix">
                        <button type="submit" id="profile-update-btn">
                        Update
                        </button>
                    </div>
                </div>
            </form>
                </td>
    */}
                
            </tr></table>

        </div>
    )
}
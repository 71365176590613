import { doc, setDoc, getFirestore } from "firebase/firestore";
import { useState} from "react";

export default function Plan(props){


    //var utcSeconds = 1234567890;
    //var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
    //d.setUTCSeconds(utcSeconds);

const [autoRenew, setAutoRenew] = useState(props.autoRenew);
const [uid0, setUid0] = useState(props.uid0);
const [uid1, setUid1] = useState(props.uid1);
const [uid2, setUid2] = useState(props.uid2);
const [uid3, setUid3] = useState(props.uid3);
const [uid4, setUid4] = useState(props.uid4);
const [uid5, setUid5] = useState(props.uid5);
    
    function save(event){
        if(props.user){
            setDoc(doc(props.db, "users", props.user.uid), {
                plans:{
                    0:{
                        autoRenew: autoRenew,
                        uid0:uid0,
                        uid1:uid1,
                        uid2:uid2,
                        uid3:uid3,
                        uid4:uid4,
                        uid5:uid5
                    }
                }
            }, { merge: true });
        }
    }
    return(
        <div clasName="plan">
            ID: {props.id} <br />
            Name: {props.name} <br />
            UID: <input id="uid-0" value={uid0} onChange={(e) => setUid0(e.target.value)} /> <br />
            UID: <input id="uid-1" value={uid1} onChange={(e) => setUid1(e.target.value)} /> <br />
            UID: <input id="uid-2" value={uid2} onChange={(e) => setUid2(e.target.value)} /> <br />
            UID: <input id="uid-3" value={uid3} onChange={(e) => setUid3(e.target.value)} /> <br />
        {props.name == "quad mountly" ?
          <> 
            UID: <input id="uid-4" value={uid4} onChange={(e) => setUid4(e.target.value)} /> <br />
            UID: <input id="uid-5" value={uid5} onChange={(e) => setUid5(e.target.value)} /> <br />
            </> 
            :""}
            Started: {new Date(props.start).toString()} <br />
            Vailed till: {new Date(props.start+props.duration).toString()} <br />
            Auto renew: 
            {autoRenew ? <input type="radio" id="renew-yes" name="renew" checked /> : <input type="radio" id="renew-yes" name="renew" onChange={(e) => setAutoRenew(true)} />}
            <label htmlFor="renew-yes">Yes</label>&nbsp;&nbsp;
            {autoRenew ? <input type="radio" id="renew-no" name="renew" onChange={(e) => setAutoRenew(false)} /> : <input type="radio" id="renew-no" name="renew" checked />}
            <label htmlFor="renew-no">No</label><br />
            <button onClick={save}>Save</button>   
        </div>
    )
}
import Flag from './Flag';

export default function Lang(props){
    return(

    <div className="main-container">
        <br />
        <table id="lang-table">
            <tr>
                <td>
                    <img src="../assets/human-icon.svg" title="Translated by human" height="32px" style={{marginRight:"32px"}}/> 
                </td>
                <td>
                    <a href="/en/advance">
                    <img class="lang-icon" src="../assets/flags/en.svg" /> 
                    </a>
                </td>
                <td>
                    <a href="/es/advance">
                    <img class="lang-icon" src="../assets/flags/es.svg" />
                    </a>
                </td>
                <td>
                    <a href="/pt_BR/advance">
                    <img class="lang-icon" src="../assets/flags/pt_BR.svg" />
                    </a>
                </td>
                <td>
                    <a href="/hi/advance">
                    <img class="lang-icon" src="../assets/flags/hi.svg" />
                    </a>
                </td>
                <td>
                    <a href="/ko/advance">
                    <img class="lang-icon" src="../assets/flags/ko.svg" />
                    </a>
                </td>
            </tr>
        </table>
        </div>
    )
}
import { HashLink as Link } from 'react-router-hash-link';
import Lang from '../Lang';
import QuickNav from '../QuickNav';

//ver 240121
//otão >botão 377

export default function HowTo_hi(){
    document.title = "Power Mouse X - Instruções de uso";
    return (
        <>
        <Lang/>
        <QuickNav chapters={["इंस्टालेशन के बाद","इशारों","माउस कॉम्बो","कस्टम नया टैब","खींचें विकल्प.","पूर्वानुमान (फ़ायरफ़ॉक्स पर उपलब्ध नहीं)","साइड बटन कॉन्फ़िगर करें (फ़ायरफ़ॉक्स पर उपलब्ध नहीं)","पिन एक्सटेंशन आइकन","एक्सटेंशन अक्षम करें"]}/>

        <div className="main-container">
                <div id="h-t-languages"></div>
                <div className="page-header"><p>Power Mouse X को इस्तमाल करना जाने</p></div>
        
                <div id="h-t-after-installation" className="main-title"><p>इंस्टालेशन के बाद</p></div>
                <div className="article">
                    यदि आपने अभी एक्सटेंशन इंस्टॉल किया है तो यह नए टैब पर काम करेगा, लेकिन पहले से खुले टैब पर काम नहीं करेगा। <mark>पहले खोले गए टैब को पुनः लोड करना सुनिश्चित करें।
                    यह भी ध्यान दें (सभी) <b> एक्सटेंशन </b> ऐड-ऑन  <b>डाउनलोड प्लेटफ़ॉर्म </b>  जैसे क्रोम वेब स्टोर और <b>गैर-यूआरएल साइटों</b>  जैसे पहले पृष्ठ पर काम नहीं करते हैं <b> जब आप</b> एक नया टैब खोलते हैं।</mark> कुछ समाधानों  के <a href="#h-t-custom-new-tab">कस्टम नया टैब</a> अनुभाग देखिए।
                    <p>
                        इस गाइड के आकार से अभिभूत न हों, इसका अधिकांश भाग बिल्कुल स्पष्ट है।  यदि आप ट्रैकपैड का उपयोग कर रहे हैं तो आप पहले <a href="#h-t-drag-options">खींचें विकल्प.</a> की जांच करना चाहेंगे।
                    </p>
                </div>
        
                <div id="h-t-gestures" className="main-title">
                    <p>इशारों</p>
                </div>
                <div className="article">
                    <p>
                        दाएँ माउस बटन को दबाकर रखें और "इशारे" बनाने के लिए इसे किनारे की ओर खींचें।
                        माउस कर्सर के चारों ओर आठ आइकन के साथ एक रिंग दिखाई देगी।
                        आप जो क्रिया करना चाहते हैं उसका प्रतिनिधित्व करने वाले आइकन पर माउस पॉइंटर रखें और फिर माउस बटन छोड़ दें।
                    </p>
                    <p>
                        डिफ़ॉल्ट इशारे हैं:
                    </p><img class="h-t-img" src="../assets/ring.jpg" id="h-t-ring-img" alt="ring" width="225px" height="225px" />
                    <div style={{float:"left", marginRight:"32px"}}>
                        <img class="h-t-img arrow-icon" src="../assets/arrow-w.svg" alt="west arrow"/> पिछले पृष्ठ पर जाएँ.<br />
                        <img class="h-t-img arrow-icon" src="../assets/arrow-n.svg" alt="north arrow"/> मुख्य पृष्ठ पर जाएँ.<br />
                        <img class="h-t-img arrow-icon" src="../assets/arrow-e.svg" alt="east arrow"/> अगले पृष्ठ पर जाएँ.<br />
                        <img class="h-t-img arrow-icon" src="../assets/arrow-s.svg" alt="south arrow"/> पृष्ठ ताज़ा करें।<br />
                    </div>
                    <div>
                        <img class="h-t-img arrow-icon" src="../assets/arrow-nw.svg" alt="north west arrow"/> साइट पर जाएँ: www.Google.com.<br />
                        <img class="h-t-img arrow-icon" src="../assets/arrow-ne.svg" alt="north east arrow"/> साइट पर जाएँ: www.Youtube.com.<br />
                        <img class="h-t-img arrow-icon" src="../assets/arrow-se.svg" alt="south east arrow"/> साइट पर जाएँ: www.Facebook.com.<br />
                        <img class="h-t-img arrow-icon" src="../assets/arrow-sw.svg" alt="south west arrow"/> साइट पर जाएँ: एक्सटेंशन ट्यूटोरियल।<br />
                    </div>
                    <p>
                        रिंग अनुकूलन योग्य है, सेटिंग्स मेनू में, आप जोड़ें \ हटाएं क्रियाओं को बदल सकते हैं, साथ ही, उनके स्थान भी बदल सकते हैं।  
                        अंगूठी का आकार भी अनुकूलन योग्य है, हम आपको इसे उतना छोटा बनाने के लिए प्रोत्साहित करते हैं जितना आप आरामदायक हों।
                    </p>
                    <p>
                    आप रिंग को सक्रिय कर सकते हैं और माउस को हिलाए बिना एक इशारा चुन सकते हैं, यह टचपैड का उपयोग करने वाले लोगों के लिए विशेष रूप से उपयोगी है।  ऐसा करने के लिए, राइट क्लिक को दबाकर रखें, फिर कीबोर्ड पर तीर कुंजियों को दबाकर रखें जो रिंग पर इशारों की स्थिति के अनुरूप हों (आप डायग में स्थित इशारों के लिए दो तीर दबा सकते हैं). तीर कुंजियाँ दबाए रखते हुए दायाँ बटन छोड़ें।
                    </p>
                    <p>
                    यदि माउस के इशारे राइट क्लिक के किसी अन्य उपयोग में बाधा डालते हैं, तो आप नियंत्रण बटन को दबाकर रखकर इशारे के तंत्र को बायपास कर सकते हैं।
                    अधिक जानकारी और स्थायी समाधान के लिए कृपया <a href="#h-t-disable-extension">एक्सटेंशन अक्षम करें</a> का अनुभाग देखें।
                    </p>
                    <p>
                        नीचे समर्थित कार्रवाइयां और उनके आइकन हैं, ध्यान दें कि कुछ कार्रवाइयां माउस कॉम्बो के रूप में भी उपलब्ध हैं और अतिरिक्त कार्रवाइयां <a href="#h-t-drag-options">ड्रैग विकल्प अनुभाग</a>.
                    </p>
                    <p>
                    यदि आपको यहां अपनी वांछित कार्रवाई नहीं मिल पाती है, तो आप इसका <Link to="/en/contact">अनुरोध</Link> कर सकते हैं, और हम इसे भविष्य के संस्करणों में शामिल करने पर विचार करेंगे।
                    </p>
                    <table>
                        <tr>
                            <td style={{borderWidth: '0'}}>
                                <table style={{verticalAlign:"bottom"}}>
                                    <tr><td>पूर्वनिर्धारित पृष्ठ पर जाएँ.</td><td><img src="../assets/go2Site.svg" alt="go to predefine page icon" className="icon" /></td></tr>
                                    <tr><td>मुख्य पृष्ठ पर जाएँ.</td><td><img src="../assets/go2Main.svg" alt="go to the main page icon" className="icon" /></td></tr>
                                    <tr><td>अगले पृष्ठ पर जाएँ.</td><td><img src="../assets/goForward.svg" alt="go to the next page icon" className="icon" /></td></tr>
                                    <tr><td>पिछले पृष्ठ पर जाएँ.</td><td><img src="../assets/goBack.svg" alt="go to the previous page icon" className="icon" /></td></tr>
                                    <tr><td>7 पृष्ठ पीछे जाएँ (सुपर बैक)</td><td><img src="../assets/goSuperBack.svg" alt="Go 7 pages back, Super back, icon" className="icon" /></td></tr>
                                    <tr><td>पृष्ठ में उपर की तरफ़ जायें।</td><td><img src="../assets/goUp.svg" alt="go to the top of the page icon" className="icon" /></td></tr>
                                    <tr><td>पृष्ठ में नीचे की तरफ़ जायें।</td><td><img src="../assets/goDown.svg" alt="go to the bottom of the page icon" className="icon" /></td></tr>
                                    <tr><td>पिछले देखे गए टैब पर जाएँ.</td><td><img src="../assets/previousViewedTab.svg" alt="go to the previous viewed tab icon" className="icon" /></td></tr>
                                    <tr><td>पहले टैब पर स्विच करें.</td><td><img src="../assets/go2FirstTab.svg" alt="Switch to the first tab icon" className="icon" /></td></tr>
                                    <tr><td>अंतिम टैब पर स्विच करें.</td><td><img src="../assets/go2LastTab.svg" alt="Switch to the last tab icon" className="icon" /></td></tr>
                                    <tr><td>पिछला टैब स्विच करें.</td><td><img src="../assets/go2PreviousTab.svg" alt="Switch to the previous tab icon" className="icon" /></td></tr>
                                    <tr><td>अगला टैब स्विच करें.</td><td><img src="../assets/go2NextTab.svg" alt="Switch to the next tab icon" className="icon" /></td></tr>
                                    <tr><td>नया टैब खोलें.</td><td><img src="../assets/newTab.svg" alt="open new tab icon" className="icon" /></td></tr>
                                </table>
                            </td>
                            <td style={{borderWidth: '0'}}>
                                <table>
                                    <tr><td>नई विंडो खोलें.</td><td><img src="../assets/newWindow.svg" alt="open new window icon" className="icon" /></td></tr>
                                    <tr><td>नई निजी विंडो खोलें.</td><td><img src="../assets/newPrivateWindow.svg" alt="open new private window icon" className="icon" /></td></tr>
                                    <tr><td>टैब बंद करें।</td><td><img src="../assets/closeTab.svg" alt="close tab icon" className="icon" /></td></tr>
                                    <tr><td>दाईं ओर टैब बंद करें.</td><td><img src="../assets/closeRightTabs.svg" alt="Close tabs to the right icon" className="icon" /></td></tr>
                                    <tr><td>बाईं ओर के टैब बंद करें.</td><td><img src="../assets/closeLeftTabs.svg" alt="Close tabs to the left" className="icon" /></td></tr>
                                    <tr><td>इस साइट के सभी टैब बंद करें.</td><td><img src="../assets/closeSiteTabs.svg" alt="Close all tabs of this site icon" className="icon" /></td></tr>
                                    <tr><td>अन्य सभी टैब बंद करें.</td><td><img src="../assets/closeOtherTabs.svg" alt="Close all other tabs icon" className="icon" /></td></tr>
                                    <tr><td>हाल ही में बंद किया गया पुनर्स्थापित करें.</td><td><img src="../assets/restore.svg" alt="restore recently closed icon" className="icon" /></td></tr>
                                    <tr><td>टैब म्यूट/अनम्यूट करें.</td><td><img src="../assets/mute.svg" alt="mute icon" className="icon" />&nbsp;<img src="../assets/unmute.svg" alt="unmute icon" className="icon" /></td></tr>
                                    <tr><td>डुप्लिकेट टैब.</td><td><img src="../assets/duplicateTab.svg" alt="duplicate tab icon" className="icon" /></td></tr>
                                    <tr><td>टैब को पिन/अनपिन करें।</td><td><img src="../assets/pinTab.svg" alt="pin icon" className="icon" />&nbsp;<img src="../assets/unpinTab.svg" alt="unpin icon" className="icon" /></td></tr>
                                    <tr><td>टैब को सार्वजनिक/निजी बनाएं.</td><td><img src="../assets/public.svg" alt="make tab public icon" className="icon" />&nbsp;<img src="../assets/private.svg" alt="make tab private icon" className="icon" /></td></tr>
                                    <tr><td>&nbsp;</td><td>&nbsp;</td></tr>
                                </table>
                            </td>
                            <td style={{borderWidth: '0'}}>
                                <table>
                                    <tr><td>टैब को पहले स्थान पर ले जाएँ.</td><td><img src="../assets/moveTabToStart.svg" alt="Move tab to the first position icon" className="icon" /></td></tr>
                                    <tr><td>टैब को अंतिम स्थिति पर ले जाएं.</td><td><img src="../assets/moveTabToEnd.svg" alt="Move tab to the last position icon" className="icon" /></td></tr>
                                    <tr><td>विंडो को छोटा करें.</td><td><img src="../assets/minimize.svg" alt="Minimize window icon" className="icon" /></td></tr>
                                    <tr><td>अधिकतम विंडो.</td><td><img src="../assets/maximize.svg" alt="Maximize window icon" className="icon" />&nbsp;<img src="../assets/unmaximize.svg" alt="unmaximize window icon" className="icon" /></td></tr>
                                    <tr><td>पूर्ण स्क्रीन।</td><td><img src="../assets/fullScreen.svg" alt="Full screen icon" className="icon" />&nbsp;<img src="../assets/unfullScreen.svg" alt="unfull screen icon" className="icon" /></td></tr>
                                    <tr><td>विंडो का आकार और स्थान सेट करें।</td><td><img src="../assets/setWindowParameters.svg" alt="change window size and location icon" className="icon" /></td></tr>
                                    <tr><td>पृष्ठ ताज़ा करें।</td><td><img src="../assets/refresh.svg" alt="refresh icon" className="icon" /></td></tr>
                                    <tr><td>कैश के बिना पृष्ठ ताज़ा करें।</td><td><img src="../assets/refreshWithoutCache.svg" alt="Refresh without cache icon" className="icon" /></td></tr>
                                    <tr><td>परचे को छापें।</td><td><img src="../assets/printPage.svg" alt="print page icon" className="icon" /></td></tr>
                                    <tr><td>एक पाठ चिपकाएँ.</td><td><img src="../assets/pasteText.svg" alt="paste a text icon" className="icon" /></td></tr>
                                    <tr><td>टैब URL कॉपी करें.</td><td><img src="../assets/copyURL.svg" alt="copy tab url icon" className="icon" /></td></tr>
                                    <tr><td>ज़ूम इन/आउट करें</td><td><img src="../assets/zoomIn.svg" alt="zoom in icon" className="icon" />&nbsp;<img src="../assets/zoomOut.svg" alt="zoom out icon" className="icon" /></td></tr>
                                    <tr><td>&nbsp;</td><td>&nbsp;</td></tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                    <p>
                        अधिकांश कार्यों के विकल्प स्वतः स्पष्ट हैं, उनमें से कुछ को और अधिक स्पष्टीकरण की आवश्यकता है:
                        <ul>
                            <li>कुछ कार्रवाइयों के लिए अतिरिक्त जानकारी प्रदान करने की आवश्यकता होगी, उदाहरण के लिए "एक टेक्स्ट चिपकाएँ" क्रिया के लिए पाठ को चिपकाने की आवश्यकता होती है और वह नाम जो क्रिया को हॉवर करते समय प्रदर्शित किया जाएगा। </li>
                            <li>"मुख्य पृष्ठ पर जाएँ" क्रिया आपको डोमेन के मुख्य पृष्ठ पर ले जाती है।</li>
                            <li>"रीफ्रेश पेज" क्रिया गैर-निजी मोड में ब्राउज़र पर रीलोड बटन दबाने के समान है।</li>
                            <li>"कैश के बिना पेज रीफ्रेश करें" क्रिया सभी पेज डेटा को फिर से डाउनलोड करती है, यह निजी मोड में ब्राउज़र पर रीलोड बटन दबाने के समान है। </li>
                            <li>कुछ क्रियाएँ स्थिति पर निर्भर होती हैं, उदाहरण के लिए, पूर्ण स्क्रीन मोड में पूर्ण स्क्रीन क्रिया का उपयोग करने से पूर्ण स्क्रीन मोड से बाहर निकल जाएगा।</li>
                        </ul>
                    </p>    
                </div>

                {/*<div id="h-t-quick-search" className="main-title">
                    <p>त्वरित खोज बार </p>
                </div>
                त्वरित खोज बार आपको तुरंत किसी साइट पर जाने, वेब पर खोज करने या टैब खोजने की अनुमति देता है।
                QSB का उपयोग करने के लिए बस दायाँ माउस बटन दबाएँ और टाइप करना प्रारंभ करें।
                <div className="article">
                    <div className="sub-title">
                        <p>किसी अन्य साइट पर जाकर वेब पर खोजें</p>
                    </div>
                    <p>
                        आप त्वरित खोज बार का उपयोग करके किसी साइट पर लगभग तुरंत नेविगेट कर सकते हैं।
                    </p>        
                    साइट का नाम टाइप करना प्रारंभ करें (माउस बटन को अभी न छोड़ें)।  QSB आप जिस साइट की तलाश कर रहे हैं उसका अनुमान लगाने और उसे प्रदर्शित करने का प्रयास करेगा।  एक बार जब बार साइट की सही भविष्यवाणी कर देता है (आमतौर पर केवल कुछ अक्षर टाइप करने के बाद) तो आप माउस बटन छोड़ सकते हैं और आपको साइट पर भेज दिया जाएगा।
                    <p>
                    <img class="h-t-img" src="../assets/quick-search-bar.gif" alt="search bar" />
                        <p>
                        आप वेब पर खोजने के लिए त्वरित खोज बार का उपयोग कर सकते हैं।
                        साइट के नाम के बजाय एक खोज क्वेरी लिखें, किसी भी समय खोज बार साइट का अनुमान नहीं लगा सकता है, यह मान लेगा कि आप वेब पर खोज करना चाहते हैं और इसके दाईं ओर 
                    <img class="h-t-img" src="../assets/search.svg" alt="search icon" className="icon" /> इसके दाईं ओर एक खोज प्रतीक दिखाएगा। 
                    एक बार जब आप माउस बटन छोड़ देंगे तो आप किसी खोज इंजन के खोज क्वेरी परिणाम पृष्ठ पर चले जाएंगे।
                    पेड सब्सक्रिप्शन वाले लोग सर्च इंजन चुन सकते हैं।
                        </p>
                    </p>
                    <div className="sub-title">
                        <p>
                            एक टैब खोजें.
                        </p>
                    </div>
                    <div>
                        यदि आपने कई टैब खोले हैं, तो दूसरे टैब को खोजने और उस पर स्विच करने का सबसे आसान तरीका त्वरित खोज बार का उपयोग करना हो सकता है।
                        Shift + Tab दबाएँ, आपको बार पर <img class="h-t-img" src="../assets/leftRightArrow.svg" alt="search tab icon" className="icon" />  "खोज टैब मोड" दर्शाने वाला एक आइकन दिखाई देगा।  यदि आप रद्द करना चाहते हैं, तो फिर से Shift + Tab दबाएँ।
                        आप दायां माउस बटन दबाए रखते हुए Shift + Tab या "/" दबाकर इस सेटिंग के साथ QSB खोल सकते हैं।  बाएं हाथ के लोगों के लिए "/" बटन का उपयोग करना आसान हो सकता है, लेकिन Shift + Tab दबाने के विपरीत, QSB खुलने के बाद "/" दबाने से "खोज टैब मोड" स्थिति नहीं बदलेगी।
                    </div>
                    <div className="sub-title">
                        <p>
                            दोनों हाथों से टाइप करना
                        </p>
                    </div>
                    एक हाथ से टाइप करना असुविधाजनक हो सकता है, खासकर जब आप एक लंबी खोज क्वेरी लिख रहे हों।
                    दोनों हाथों से टाइप करने में सक्षम होने के लिए दायां माउस बटन दबाकर और स्पेस बार दबाकर त्वरित खोज बार खोलें।  अब आप माउस बटन को छोड़ सकते हैं और दोनों हाथों का उपयोग कर सकते हैं, बार पर एक लॉक चिन्ह <img class="h-t-img" src="../assets/lock.svg" alt="lock icon" className="icon" /> इंगित करता है कि बार रहेगा।  जब आप टाइप करना समाप्त कर लें तो Enter कुंजी दबाएँ।
                    <div className="sub-title">
                        <p>
                            किसी साइट को नए टैब में खोलना
                        </p>
                    </div>
                    <p>
                        यदि आप किसी साइट पर जाना चाहते हैं या नए टैब में वेब खोजना चाहते हैं, तो टैब कुंजी दबाएं।
                        आपको बार पर एक प्लस चिन्ह दिखाई देगा <img class="h-t-img" src="../assets/newTab.svg" alt="plus icon" className="icon" /> जो "नया टैब मोड" दर्शाता है, जिसका अर्थ है कि परिणाम एक नए टैब में होंगे।  यदि आप रद्द करना चाहते हैं, तो Tab दोबारा दबाएँ।
                    </p>
                    <p>
                        आप दायां माउस बटन दबाए रखते हुए टैब या "+" दबाकर इस सेटिंग के साथ त्वरित खोज बार खोल सकते हैं।  बाएं हाथ के लोगों के लिए "+" बटन का उपयोग करना आसान हो सकता है, लेकिन टैब बटन के विपरीत, त्वरित खोज बार खुलने के बाद इसे दबाने से "नया टैब मोड" स्थिति नहीं बदलेगी।
                    </p>
                    <div className="sub-title">
                        <p>
                            बहुभाषी समर्थन
                        </p>
                    </div>
                    <p>
                        यदि आपके पास एक से अधिक कीबोर्ड लेआउट हैं, तो संभवतः आपने कई बार स्वयं को गलत लेआउट का उपयोग करके टाइप करते हुए पाया होगा।  क्या यह अच्छा नहीं होगा यदि आप इसे एक बटन दबाकर ठीक कर सकें? सेटिंग पेज पर जाएं और अपना कीबोर्ड लेआउट सेट करें, अगली बार जब आप गलती से अपने दूसरे लेआउट का उपयोग करके त्वरित खोज बार में टाइप करेंगे तो F12 दबाएं और टेक्स्ट जादुई रूप से परिवर्तन हो जाएगा. टेक्स्ट को दूसरे कीबोर्ड लेआउट में बदलने के लिए फिर से F12 टाइप करें।
                    </p>
                    <p>
                    आप अधिकतम चार लेआउट का उपयोग कर सकते हैं, प्रत्येक F12 प्रेस एक्सटेंशन को इस क्रम में लेआउट 1 और अन्य लेआउट के बीच कनवर्ट करने का प्रयास करेगा: 2&#8594;1, 3&#8594;1, 4&#8594;1, 1&#8594;2, 1&#8594;3, 1&#8594;4.
                    </p>
                    <div className="sub-title">
                        <p>
                            अगर आपका मन बदल जाए
                        </p>
                    </div>
                    किसी भी समय आप एस्केप बटन दबाकर त्वरित खोज बार को रद्द कर सकते हैं।
    </div>*/}
                    <div id="h-t-mouse-combos" className="main-title">
                        <p>
                            माउस कॉम्बो
                        </p>
                    </div>
                    <div className="article">        
                        <p>
                            <b>टैब को बंद</b> करने के लिए दायां या मध्य माउस बटन को दो बार क्लिक करें।
                            <br />आप सेटिंग्स में डबल टैपिंग के लिए आवश्यक गति को बदल सकते हैं।
                            <br /><i>*कृपया ध्यान दें कि एज पर डबल टैपिंग राइट बटन कॉम्बो केवल तभी काम करता है जब माउस को घुमाते समय किया जाता है, डबल टैपिंग मिडिल बटन कॉम्बो सामान्य रूप से काम करता है।</i>
                        </p>
                        <p>
                            <b>एक नया टैब खोलें</b> दाएँ बटन को दबाए रखते हुए बाएँ बटन को दबाकर।
                        </p>
                        <p>
                            <b>टैब बदलें।</b> दायां बटन दबाए जाने पर व्हील बटन को स्क्रॉल करके।
                            <br />आप सेटिंग्स में टैब स्विचिंग दिशा बदल सकते हैं।
                        </p>
                        <p>
                            <b>पृष्ठ के ऊपर या नीचे जाएँ।</b> मध्य बटन को दबाए जाने पर ऊपर या नीचे स्क्रॉल करके।
                        </p>
                        <p>
                            <b>ज़ूम इन या ज़ूम आउट करें</b> मध्य बटन को दबाए जाने पर ऊपर या नीचे स्क्रॉल करके।
                            <br />यह सुविधा "पेज के ऊपर या नीचे जाएं" सुविधा के समान कॉम्बो का उपयोग करती है।  इस सुविधा का उपयोग करने के लिए, आपको सेटिंग्स में अन्य सुविधा को बदलना होगा।
                        </p>
                        <p>
                            सभी कॉम्बो को सेटिंग्स में अक्षम किया जा सकता है।
                        </p>
                    </div>
                    <div id="h-t-custom-new-tab" className="main-title">
                        <p>
                            कस्टम नया टैब
                        </p>
                    </div>
                    <div className="article">
                        <img class="h-t-img" src="../assets/starting%20page%20screenshot.png" id="h-t-custom-new-tab-img" alt="custom new tab" width="360px" height="225px" />
                        <p>
                            सभी ब्राउज़र एक्सटेंशन डिफ़ॉल्ट नए टैब पेज पर काम नहीं कर सकते.  इस समस्या को बायपास करने के लिए पावर माउस एक्स एक कस्टम नए टैब पेज का उपयोग करता है।
                        </p>
                        <p>
                            डिफ़ॉल्ट नए टैब पृष्ठ की तरह, हमारा कस्टम नया टैब साइटों के शॉर्टकट प्रदर्शित करता है।  आप उन्हें अक्षम कर सकते हैं या सेटिंग पृष्ठ में कॉन्फ़िगर कर सकते हैं।
                        </p>
                        <p>
                            आप इस कस्टम नए टैब को दो तरीकों से खोल सकते हैं:<br/>
                            1) नए टैब के लिए <a href="#h-t-mouse-combos">माउस कॉम्बो</a> का उपयोग करें. इसे एक कस्टम नया टैब खोलना चाहिए, जब तक कि आपने डिफ़ॉल्ट नया टैब खोलने के लिए सेटिंग नहीं बदल दी हो।<br/>
                            2) यदि आप कॉम्बो का उपयोग नहीं करना चाहते हैं या आप ट्रैकपैड का उपयोग कर रहे हैं, तो आप पावर माउस एक्स कस्टम नए टैब को अपने डिफ़ॉल्ट टैब के रूप में सेट कर सकते हैं।  "ऐसा करने के लिए आपको ""पावर माउस एक्स न्यू टैब रिप्लेसमेंट"" नामक एक पूरक ऐड-ऑन डाउनलोड करना होगा।"
                            आप पूरक ऐड-ऑन को अक्षम करके हमेशा परिवर्तन वापस ला सकते हैं।<br />
                            ध्यान दें कि आपको अभी भी क्रोम और एज गुप्त मोड में मूल नया टैब पृष्ठ मिलेगा।
                        </p>
                        इसके लिए पावर माउस एक्स नया टैब प्रतिस्थापन डाउनलोड करें:<br/>
                        <a href="https://chrome.google.com/webstore/detail/power-mouse-x-new-tab-rep/lokfekmibhakginjdmfmiafjnocinefd?hl=en-GB&authuser=0">क्रोम</a>&nbsp; 
                        <a href="https://microsoftedge.microsoft.com/addons/detail/power-mouse-x-new-tab-rep/cmapphcgabfcjcnehjchcmgodpmalafg">एज</a>&nbsp;
                        <a href="https://addons.mozilla.org/en-US/firefox/addon/powermousex-newtab-replacement/">फ़ायरफ़ॉक्स </a>
                    </div>
                    <div id="h-t-drag-options" className="main-title">
                        <p>
                            खींचें विकल्प.
                        </p>
                    </div>
                    <div className="article">        
                        <div>
                        <table id="h-t-options-table" style={{float:"right", marginLeft:"24px"}}>
                            <tr>
                                <td></td><td><b>चयनित पाठ.</b></td><td><b>लिंक</b></td><td><b>छवि</b></td>
                            </tr>
                            <tr>
                                <td><b>कॉपी<sup>(1)</sup></b></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td><td></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td>
                            </tr>
                            <tr>
                                <td><b>वेब खोज।</b></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td>
                            </tr>
                            <tr>
                                <td><b>विकिपीडिया में खोजें.<sup>(2)</sup></b></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td><td></td><td></td>
                            </tr>
                            <tr>
                                <td><b>अनुवाद<sup>(2)</sup></b></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td><td></td><td></td>
                            </tr>
                            <tr>
                                <td><b>प्रमुखता से दिखाना</b></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td><td></td><td></td>
                            </tr>
                            <tr>
                                <td><b>एक नए टैब में खोलें.</b></td><td></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td>
                            </tr>
                            <tr>
                                <td><b>पृष्ठभूमि टैब में खोलें.</b></td><td></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td>
                            </tr>
                            <tr>
                                <td><b>सार्वजनिक/निजी विंडो में खोलें(3)<sup>(3)</sup></b></td><td></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td>
                            </tr>
                            <tr>
                                <td><b>एक नई विंडो में खोलें.</b></td><td></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td>
                            </tr>                            
                            <tr>
                                <td><b>डाउनलोड करना</b></td><td></td><td></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td>
                            </tr>
                        </table>
                        <p>
                                वेब पेज तत्वों को खींचने से एक विकल्प रिंग खुलती है।  अलग-अलग तत्व अलग-अलग विकल्प प्रकट करने का कारण बनते हैं। <br /> दाईं ओर की तालिका उपलब्ध विकल्पों का सार प्रस्तुत करती है।
                            </p>
                        <img class="h-t-img" src="../assets/drag%20ring.jpg" id="h-t-ring-img" alt="drag ring" width="225px" height="225px" style={{float:"none"}} />

                            <p>
                    यदि रिंग तत्व खींचने के किसी अन्य उपयोग में हस्तक्षेप करती है, तो आप नियंत्रण बटन को दबाकर रिंग को बायपास कर सकते हैं।
                    अधिक जानकारी और स्थायी समाधान के लिए कृपया <a href="#h-t-disable-extension">एक्सटेंशन अक्षम करें</a> का अनुभाग देखें।
                    </p>
                        </div>
                        <div>
                        <p>
                            <i>
                                (1) केवल पीएनजी छवियों की प्रतिलिपि बनाई जा सकती है।
                            <br />(2) आप सेटिंग्स में लक्ष्य भाषा सेट कर सकते हैं।
                            <br />(3) आपके वर्तमान विंडो मोड पर निर्भर करता है।
                            </i>
                        </p>
                        <p>
                            ड्रैग विकल्प को सेटिंग पेज में अक्षम किया जा सकता है।
                        </p>
                        </div>
                    </div>
                    <div id="h-t-predictions" className="main-title"><p>पूर्वानुमान (फ़ायरफ़ॉक्स पर उपलब्ध नहीं)</p></div>
                    <div className="article">        
                        <p>पावर माउस एक्स आपके अगले पृष्ठ की भविष्यवाणी करने का प्रयास करता है।  शॉपिंग साइट, सर्च इंजन, फोरम आदि जैसी साइट ब्राउज़ करते समय, आप अगले पृष्ठ पर जाने के लिए इन तरीकों का उपयोग कर सकते हैं: "आगे बढ़ें" इशारे का उपयोग करके, Alt + &#8594; दबाकर या माउस का उपयोग करके। प्लेलिस्ट में अगला वीडियो देखने के लिए यूट्यूब पर इस सुविधा का उपयोग करें।
                        </p>
                        <p>कुछ साइटों पर पूर्वानुमान दूसरों की तुलना में बेहतर काम करते हैं, कभी-कभी यह बहुत अच्छा काम करता है, कभी-कभी यह काम नहीं करता है और अक्सर यह परिणामों की सूची के दूसरे पृष्ठ से ही काम करता है।  अपनी पसंदीदा साइटों पर इसका प्रयोग करें। </p>
                    </div>
                    <div id="h-t-side-buttons" className="main-title"><p>साइड बटन कॉन्फ़िगर करें (फ़ायरफ़ॉक्स पर उपलब्ध नहीं)</p></div>
                    <div className="article">        
                        <p>
                            कुछ कंप्यूटर माउस में अतिरिक्त साइड बटन होते हैं जो आमतौर पर त्वरित ब्राउज़र बैक और फॉरवर्ड नेविगेशन के लिए उपयोग किए जाते हैं।
                            PowerMouseX आपको इन बटनों में कार्यक्षमता जोड़ने की अनुमति देता है।
                            </p>
                            <p> 
                            सेटिंग पृष्ठ में, स्क्रॉलबार से उन दर्जनों क्रियाओं में से एक चुनें जिन्हें आप एक बटन दबाने पर सक्रिय करना चाहते हैं।
                            आपको फॉरवर्ड बटन (बटन #5) प्राथमिक लक्ष्य भी चुनना होगा:<br/>
                            यदि आप इसे <b>फॉरवर्ड</b> पर सेट करते हैं तो ब्राउज़र पहले आगे नेविगेट करने का प्रयास करेगा और यदि ऐसा विकल्प मौजूद नहीं है तो यह कार्रवाई करेगा। <br/>
                            यदि आप इसे <b>आगे और पूर्वानुमान</b> पर सेट करते हैं तो ब्राउज़र पहले आगे नेविगेट करने का प्रयास करेगा और यदि ऐसा विकल्प मौजूद नहीं है तो यह अगले पृष्ठ की भविष्यवाणी करने का प्रयास करेगा, यदि वह ऐसा नहीं कर सकता है तो वह कार्रवाई करेगा। <a href="#h-t-predictions">पृष्ठ की भविष्यवाणी</a> 
                            करने का प्रयास करेगा, यदि वह ऐसा नहीं कर सकता है तो वह कार्रवाई करेगा।<br/>
                            यदि आप इसे <b>ऐक्शन</b>पर सेट करते हैं, तो फॉरवर्ड बटन दबाने से केवल कार्रवाई होगी और आगे बढ़ने के लिए इसका उपयोग कभी नहीं किया जाएगा।
                            </p>
                            <p>
                            यदि आपके पास सशुल्क सदस्यता है तो आप प्राथमिक माउस बटनों में से किसी एक को दबाए रखते हुए साइड बटन दबाने से बने संयोजन में कार्यक्षमता भी जोड़ सकते हैं।
                        </p>
                    </div>
                    <div id="h-t-pin-extension-icon" className="main-title">
                        <p>
                            पिन एक्सटेंशन आइकन
                        </p>
                    </div>
                    <div className="article">  
                        <img class="h-t-img" src="../assets/popup%20menu%20screenshot.png" id="h-t-pop-up-menu-img" alt="pop-up menu" width="200px" height="223px" />
                        <p>
                            एक्सटेंशन सेटिंग्स तक पहुंचने या किसी साइट पर एक्सटेंशन को रोकने का सबसे आसान तरीका पॉप-अप मेनू है जो एक्सटेंशन आइकन दबाने पर दिखाई देता है।  यदि पावर माउस एक्स आइकन टूलबार में दिखाई नहीं देता है तो आप इसे इस तरह से ठीक कर सकते हैं:
                        </p>
                        <p>
                            <b>क्रोम में </b> एड्रेस बार के बगल में पहेली आइकन दबाएं।  फिर एक्सटेंशन नाम के आगे पिन आइकन दबाएं।
                            <br /><b>एज में </b> एड्रेस बार के बगल में पहेली आइकन दबाएं।  फिर एक्सटेंशन नाम के आगे आंख आइकन दबाएं।
                            <br /><b>फ़ायरफ़ॉक्स में </b> एड्रेस बार के आगे राइट-क्लिक करें और "कस्टमाइज़ टूलबार" चुनें।  फिर एक्सटेंशन आइकन को टूलबार पर खींचें।
                        </p>
                    </div>
                    <div id="h-t-disable-extension" className="main-title">
                        <p>
                            एक्सटेंशन अक्षम करें
                        </p>
                    </div>
                    <div className="article">        
                        <p>
                        यदि माउस जेस्चर या ड्रैग विकल्प अस्थायी रूप से हस्तक्षेप करते हैं, उदाहरण के लिए पॉप अप मेनू को प्रकट होने से रोककर, तो आप एमओयू का उपयोग करने से पहले नियंत्रण बटन दबाकर समस्या को बायपास कर सकते हैं
                        </p>
                        <p>
                            एक अन्य विकल्प टूलबार (एड्रेस बार के बगल में) में <img class="h-t-img" src="../assets/icon128.png" alt="Power Mouse X icon" className="icon" /> दबाकर और स्विच को टॉगल करके अपनी वर्तमान साइट या सभी साइटों के लिए एक्सटेंशन को अक्षम करना है।                             यदि आइकन गायब है, तो पिछला अनुभाग पढ़ें।
                        </p>
                    </div>
                    <div><br/><br/><br/><b><i>Translated from English by Darshith T N</i></b></div>
                </div>
            </>
        )
}
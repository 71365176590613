import Flag from './Flag';

export default function Lang(props){
    return(

    <div className="main-container">
        <br />
        <table id="lang-table">
            <tr>
                <td>
                    <img src="../assets/human-icon.svg" title="Translated by human" height="32px" style={{marginRight:"32px"}}/> 
                </td>
                <td>
                    <a href="/en/how to">
                    <img class="lang-icon" src="../assets/flags/en.svg" /> 
                    </a>
                </td>
                <td>
                    <a href="/es/how to">
                    <img class="lang-icon" src="../assets/flags/es.svg" />
                    </a>
                </td>
                <td>
                    <a href="/pt_BR/how to">
                    <img class="lang-icon" src="../assets/flags/pt_BR.svg" />
                    </a>
                </td>
                <td>
                    <a href="/hi/how to">
                    <img class="lang-icon" src="../assets/flags/hi.svg" />
                    </a>
                </td>
                <td>
                    <a href="/ko/how to">
                    <img class="lang-icon" src="../assets/flags/ko.svg" /> 
                    </a>
                </td>
                <td>
                    <a href="/zh_CN/how to.html">
                    <img class="lang-icon" src="../assets/flags/zh_CN.svg" />
                    </a>
                </td>
                <td>
                    <a href="/ja/how to.html">
                    <img class="lang-icon" src="../assets/flags/ja.svg" />
                    </a>
                </td>
            </tr>
            <tr>
                <td>
                    <img src="../assets/computer-icon.svg" title="Translated by machine" height="32px"/> 
                </td>
                {/* >1.3M && <85% */}
                {/*<Flag code="ar"/>
                <Flag code="am"/>
                <Flag code="bg"/>
            <Flag code="de"/>*/}
                <Flag code="bn"/>
                <Flag code="cs"/>
                <Flag code="el"/>
                {/*<Flag code="es"/>
                <Flag code="fa"/>*/}
                <Flag code="fi"/>
                {/*<Flag code="fil"/>
                <Flag code="fr"/>
                <Flag code="gu"/>*/}
                <Flag code="he"/>
                <Flag code="pl"/>
                <Flag code="ro"/>
                <Flag code="it"/>
                <Flag code="id"/>
                <Flag code="vi"/>
            </tr>
            <tr>
                <td></td>
                {/*<Flag code="hi"/>
                <Flag code="hr"/>
                <Flag code="hu"/><<
                <Flag code="kn"/>
<Flag code="lt"/>*/}
                {/*
                ml 	Malayalam
mr 	Marathi
ms 	Malay
pt_PT 	Portuguese (Portugal)
ru 	Russian
sk 	Slovak
sl 	Slovenian
sr 	Serbian
sw 	Swahili
ta 	Tamil
te 	Telugu
th 	Thai
tr 	Turkish
uk 	Ukrainian
    zh_TW 	Chinese (Taiwan)
    */}
            </tr>
        </table>
        </div>
    )
}
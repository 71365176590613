import { useNavigate } from 'react-router-dom';

export default function CancelBtn(){
    
    const navigate = useNavigate();


    return(
        <button type="button" className="cancelbtn" onClick={() => navigate(-1)} >
        Cancel
    </button> 
    )
}
import { useState } from "react";
import Plan from "./Plan"
import { doc, getDoc } from "firebase/firestore";

export default function Plans(props){
    const [plans, setPlans] = useState("null");
    const docRef = doc(props.db, "users", props.user.uid);
    getDoc(docRef).then((docSnap) =>{
    if (docSnap.data()["plans"]) {
        if(plans=="null"){setPlans(docSnap.data()["plans"]);}
        console.log(plans[0]["name"]);
      } else {
        console.log("No such document!");
      }
    });
    return(
        <div className="main-container">
          <img src="../assets/uid.png" /><br /><br />
          1) Go to settings<br />
          2) Set your subscriptions on<br />
          3) Copy your UID<br />
          4) After updating the UID wait a few minets then press the refresh arrow in the settings<br /><br />

            {plans!=="null" ? 
               <Plan id={0} name={plans[0].name} start={plans[0].start} duration={plans[0].duration} 
               autoRenew={plans[0].autoRenew} db={props.db} user={props.user}
               uid0={plans[0].uid0}
               uid1={plans[0].uid1}
               uid2={plans[0].uid2}
               uid3={plans[0].uid3}
               uid4={plans[0].uid4}
               uid5={plans[0].uid5}
                />
            : ""}
        </div>
    )
}
import Flag from './Flag';
import QuickNavItem from './QuickNavItem';

export default function QuickNav(props){
    
    return(
        <div id="h-t-quick-nav">
                <b>
                    <ul style={{listStyleType:'none', margin: '0 0 0 0', padding: '0 0 0 0'}}>
                        <QuickNavItem id={"after-installation"} chapter={props.chapters[0]}/>
                        <QuickNavItem id={"gestures"} chapter={props.chapters[1]}/>
                        {/*<QuickNavItem id={"quick-search"} chapter={props.chapters[2]}/>*/}
                        <QuickNavItem id={"mouse-combos"} chapter={props.chapters[2]}/>
                        <QuickNavItem id={"custom-new-tab"} chapter={props.chapters[3]}/>
                        <QuickNavItem id={"drag-options"} chapter={props.chapters[4]}/>
                        <QuickNavItem id={"predictions"} chapter={props.chapters[5]}/>
                        <QuickNavItem id={"side-buttons"} chapter={props.chapters[6]}/>
                        <QuickNavItem id={"pin-extension-icon"} chapter={props.chapters[7]}/>
                        <QuickNavItem id={"disable-extension"} chapter={props.chapters[8]}/>
                    </ul>
                </b>
            </div>
        )
}
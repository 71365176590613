//import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
//import '../Site.css'
//import '../Troubleshooting.css'
export default function Troubleshooting(){
    document.title = "Power Mouse X - Troubleshooting";
    return (
    <div className="main-container">
        <div id="languages"></div>
        <div className="page-header"><p>Something wrong? follow these steps</p></div>

        <div className="main-title"><p>Important</p></div>
        <div className="article">
            Extensions work only on tabs that display websites. Extensions can not work on the browser default startup page, settings page, PDF viewer etc.
        </div>

        <div className="main-title"><p>Troubleshooting steps</p></div>

        <div className="article">
            <b>If your extension doesn't function properly try the following:</b><br />
            1) Reload the tabs.<br />
            2) Reload the browser.<br />
            3) Check you didn't <Link to="/../en/how%20to#h-t-disable-extension">disable it manuely.</Link><br />
            4) Review the settings page.<br />
            5) Make sure your browser and the extension are updated to the latest versions.<br />
            6) Turn the extension off and then on in the browser extension manager.<br />
            7) <Link to="/../en/contact">Contact</Link> us.<br /><br />
            If the ring appears but does not show any icons try clauses 2 or 6 first.
        </div>    
    </div>
    )
}
import { HashLink as Link } from 'react-router-hash-link';
import LangAdvance from '../LangAdvance';

//ver 240121
//otão >botão 377

export default function HowTo_hi(){
    document.title = "Power Mouse X - Instruções de uso";
    return (
        <>
        <LangAdvance/>
        <div id="h-t-quick-nav">
        <b>
                    <ul style={{listStyleType:'none', margin: '0 0 0 0', padding: '0 0 0 0'}}>
                        <li><a href="#h-t-quick-search">त्वरित खोज बार</a></li>
                    </ul>
                </b>
            </div>
        <div className="main-container">
                <div id="h-t-languages"></div>
                <div className="page-header"><p>Power Mouse X को इस्तमाल करना जाने</p></div>
        
                <div id="h-t-quick-search" className="main-title">
                    <p>त्वरित खोज बार </p>
                </div>
                त्वरित खोज बार आपको तुरंत किसी साइट पर जाने, वेब पर खोज करने या टैब खोजने की अनुमति देता है।
                QSB का उपयोग करने के लिए बस दायाँ माउस बटन दबाएँ और टाइप करना प्रारंभ करें।
                <div className="article">
                    <div className="sub-title">
                        <p>किसी अन्य साइट पर जाकर वेब पर खोजें</p>
                    </div>
                    <p>
                        आप त्वरित खोज बार का उपयोग करके किसी साइट पर लगभग तुरंत नेविगेट कर सकते हैं।
                    </p>        
                    साइट का नाम टाइप करना प्रारंभ करें (माउस बटन को अभी न छोड़ें)।  QSB आप जिस साइट की तलाश कर रहे हैं उसका अनुमान लगाने और उसे प्रदर्शित करने का प्रयास करेगा।  एक बार जब बार साइट की सही भविष्यवाणी कर देता है (आमतौर पर केवल कुछ अक्षर टाइप करने के बाद) तो आप माउस बटन छोड़ सकते हैं और आपको साइट पर भेज दिया जाएगा।
                    <p>
                    <img class="h-t-img" src="../assets/quick-search-bar.gif" alt="search bar" />
                        <p>
                        आप वेब पर खोजने के लिए त्वरित खोज बार का उपयोग कर सकते हैं।
                        साइट के नाम के बजाय एक खोज क्वेरी लिखें, किसी भी समय खोज बार साइट का अनुमान नहीं लगा सकता है, यह मान लेगा कि आप वेब पर खोज करना चाहते हैं और इसके दाईं ओर 
                    <img class="h-t-img" src="../assets/search.svg" alt="search icon" className="icon" /> इसके दाईं ओर एक खोज प्रतीक दिखाएगा। 
                    एक बार जब आप माउस बटन छोड़ देंगे तो आप किसी खोज इंजन के खोज क्वेरी परिणाम पृष्ठ पर चले जाएंगे।
                    पेड सब्सक्रिप्शन वाले लोग सर्च इंजन चुन सकते हैं।
                        </p>
                    </p>
                    <div className="sub-title">
                        <p>
                            एक टैब खोजें.
                        </p>
                    </div>
                    <div>
                        यदि आपने कई टैब खोले हैं, तो दूसरे टैब को खोजने और उस पर स्विच करने का सबसे आसान तरीका त्वरित खोज बार का उपयोग करना हो सकता है।
                        Shift + Tab दबाएँ, आपको बार पर <img class="h-t-img" src="../assets/leftRightArrow.svg" alt="search tab icon" className="icon" />  "खोज टैब मोड" दर्शाने वाला एक आइकन दिखाई देगा।  यदि आप रद्द करना चाहते हैं, तो फिर से Shift + Tab दबाएँ।
                        आप दायां माउस बटन दबाए रखते हुए Shift + Tab या "/" दबाकर इस सेटिंग के साथ QSB खोल सकते हैं।  बाएं हाथ के लोगों के लिए "/" बटन का उपयोग करना आसान हो सकता है, लेकिन Shift + Tab दबाने के विपरीत, QSB खुलने के बाद "/" दबाने से "खोज टैब मोड" स्थिति नहीं बदलेगी।
                    </div>
                    <div className="sub-title">
                        <p>
                            दोनों हाथों से टाइप करना
                        </p>
                    </div>
                    एक हाथ से टाइप करना असुविधाजनक हो सकता है, खासकर जब आप एक लंबी खोज क्वेरी लिख रहे हों।
                    दोनों हाथों से टाइप करने में सक्षम होने के लिए दायां माउस बटन दबाकर और स्पेस बार दबाकर त्वरित खोज बार खोलें।  अब आप माउस बटन को छोड़ सकते हैं और दोनों हाथों का उपयोग कर सकते हैं, बार पर एक लॉक चिन्ह <img class="h-t-img" src="../assets/lock.svg" alt="lock icon" className="icon" /> इंगित करता है कि बार रहेगा।  जब आप टाइप करना समाप्त कर लें तो Enter कुंजी दबाएँ।
                    <div className="sub-title">
                        <p>
                            किसी साइट को नए टैब में खोलना
                        </p>
                    </div>
                    <p>
                        यदि आप किसी साइट पर जाना चाहते हैं या नए टैब में वेब खोजना चाहते हैं, तो टैब कुंजी दबाएं।
                        आपको बार पर एक प्लस चिन्ह दिखाई देगा <img class="h-t-img" src="../assets/newTab.svg" alt="plus icon" className="icon" /> जो "नया टैब मोड" दर्शाता है, जिसका अर्थ है कि परिणाम एक नए टैब में होंगे।  यदि आप रद्द करना चाहते हैं, तो Tab दोबारा दबाएँ।
                    </p>
                    <p>
                        आप दायां माउस बटन दबाए रखते हुए टैब या "+" दबाकर इस सेटिंग के साथ त्वरित खोज बार खोल सकते हैं।  बाएं हाथ के लोगों के लिए "+" बटन का उपयोग करना आसान हो सकता है, लेकिन टैब बटन के विपरीत, त्वरित खोज बार खुलने के बाद इसे दबाने से "नया टैब मोड" स्थिति नहीं बदलेगी।
                    </p>
                    <div className="sub-title">
                        <p>
                            बहुभाषी समर्थन
                        </p>
                    </div>
                    <p>
                        यदि आपके पास एक से अधिक कीबोर्ड लेआउट हैं, तो संभवतः आपने कई बार स्वयं को गलत लेआउट का उपयोग करके टाइप करते हुए पाया होगा।  क्या यह अच्छा नहीं होगा यदि आप इसे एक बटन दबाकर ठीक कर सकें? सेटिंग पेज पर जाएं और अपना कीबोर्ड लेआउट सेट करें, अगली बार जब आप गलती से अपने दूसरे लेआउट का उपयोग करके त्वरित खोज बार में टाइप करेंगे तो F12 दबाएं और टेक्स्ट जादुई रूप से परिवर्तन हो जाएगा. टेक्स्ट को दूसरे कीबोर्ड लेआउट में बदलने के लिए फिर से F12 टाइप करें।
                    </p>
                    <p>
                    आप अधिकतम चार लेआउट का उपयोग कर सकते हैं, प्रत्येक F12 प्रेस एक्सटेंशन को इस क्रम में लेआउट 1 और अन्य लेआउट के बीच कनवर्ट करने का प्रयास करेगा: 2&#8594;1, 3&#8594;1, 4&#8594;1, 1&#8594;2, 1&#8594;3, 1&#8594;4.
                    </p>
                    <div className="sub-title">
                        <p>
                            अगर आपका मन बदल जाए
                        </p>
                    </div>
                    किसी भी समय आप एस्केप बटन दबाकर त्वरित खोज बार को रद्द कर सकते हैं।
                    </div>
                    
                    <div><br/><br/><br/><b><i>Translated from English by Darshith T N</i></b></div>
                </div>
            </>
        )
}
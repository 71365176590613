
import { HashLink as Link } from 'react-router-hash-link';
import Lang from '../Lang';
import DragOptionsTable from '../DragOptionsTable';

export default function HowTo(){
    document.title = "Power Mouse X - How To";
    return (
        <>
        <Lang/>
            <div id="h-t-quick-nav">
                <b>
                    <ul style={{listStyleType:'none', margin: '0 0 0 0', padding: '0 0 0 0'}}>
                        {/*<li><a href="#h-t-after-installation">After installation</a></li>*/}
                        <li><a href="#h-t-gestures">제스처</a></li>
                        {/*<li><a href="#h-t-quick-search">Quick search bar</a></li>*/}
                        <li><a href="#h-t-mouse-combos">마우스콤보</a></li>
                        <li><a href="#h-t-custom-new-tab">사용자 정의 새 탭</a></li>
                        <li><a href="#h-t-drag-options">드래그 옵션</a></li>
                        <li><a href="#h-t-predictions">예측</a></li>
                        <li><a href="#h-t-side-buttons">측면 버튼 구성</a></li>
                        <li><a href="#h-t-pin-extension-icon">핀 확장 아이콘</a></li>
                        <li><a href="#h-t-disable-extension">확장 사용 안 함</a></li>
                    </ul>
                </b>
            </div>
            <div className="main-container">
                <div id="h-t-languages"></div>
                <div className="page-header"><p>Power Mouse X 사용법 알아보기</p></div>
        
                {/*<div id="h-t-after-installation" className="main-title"><p>After installation</p></div>*/}
                <div className="article">
                이 가이드의 크기에 당황하지 마세요. 대부분의 내용은 쉽게 이해할 수 있습니다. 트랙패드를 사용하는 경우 다음을 확인할 수 있습니다. <a href="#h-t-drag-options">드래그 옵션</a> 섹션 먼저.
                    <p>
                    <mark>Chrome 웹 스토어와 같은 <b>확장 프로그램 다운로드 플랫폼</b>이나 새 탭을 열 때 나타나는 <b>첫 페이지</b>와 같은 <b>비 URL 사이트</b>에서는 (모든) 확장 프로그램이 <b>작동하지 않습니다</b>.</mark> 참조. <a href="#h-t-custom-new-tab">사용자 정의 새 탭</a> 몇 가지 해결책에 대한 섹션입니다.
                    </p>
                </div>
        
                <div id="h-t-gestures" className="main-title">
                    <p>제스처</p>
                </div>
                <div className="article">
                    <p>
                        마우스 오른쪽 버튼을 길게 누르고 옆으로 끌면 "제스처"가 됩니다.
                        8개의 아이콘이 있는 마우스 커서 주위에 링이 나타납니다.
                        수행할 작업을 나타내는 아이콘에 마우스 포인터를 놓은 다음 마우스 버튼을 놓습니다.
                    </p>
                    <p>
                        기본 제스처는 다음과 같습니다.
                    </p><img class="h-t-img" src="../assets/ring.jpg" id="h-t-ring-img" alt="ring" width="225px" height="225px" />
                    <div style={{float:"left", marginRight:"32px"}}>
                        <img class="h-t-img arrow-icon" src="../assets/arrow-w.svg" alt="west arrow"/> 이전 페이지로 이동합니다.<br />
                        <img class="h-t-img arrow-icon" src="../assets/arrow-n.svg" alt="north arrow"/> 본 페이지로 이동합니다<br />
                        <img class="h-t-img arrow-icon" src="../assets/arrow-e.svg" alt="east arrow"/> 다음 페이지로 이동합니다.<br />
                        <img class="h-t-img arrow-icon" src="../assets/arrow-s.svg" alt="south arrow"/> 새로고침 페이지<br />
                    </div>
                    <div>
                        <img class="h-t-img arrow-icon" src="../assets/arrow-nw.svg" alt="north west arrow"/> 사이트: www.Google.com.<br />
                        <img class="h-t-img arrow-icon" src="../assets/arrow-ne.svg" alt="north east arrow"/> 사이트: www.Youtube.com.<br />
                        <img class="h-t-img arrow-icon" src="../assets/arrow-se.svg" alt="south east arrow"/> 사이트: www.Facebook.com.<br />
                        <img class="h-t-img arrow-icon" src="../assets/arrow-sw.svg" alt="south west arrow"/> 사이트로 이동: 확장 튜토리얼.<br />
                    </div>
                    <p>
                    링은 사용자 지정할 수 있으며 설정 메뉴에서 작업을 변경 \ 추가 \ 제거할 수 있을 뿐만 아니라 위치를 변경할 수 있습니다. 
                        반지 사이즈도 맞춤 제작이 가능하여 편한 대로 작게 제작하는 것이 좋습니다.
                    </p>
                    <p>
                    마우스를 움직이지 않고 링을 활성화하고 제스처를 선택할 수 있으며, 이는 터치패드를 사용하는 사람들에게 특히 유용합니다. 이렇게 하려면 오른쪽 클릭을 누른 상태에서 
                    그런 다음 링의 제스처 위치에 해당하는 키보드의 화살표 키를 길게 누릅니다 (대각선으로 위치한 제스처는 두 개의 화살표를 누를 수 있습니다). 화살표 키를 누른 상태에서 오른쪽 버튼을 놓습니다.
                    </p>
                    <p>
                    마우스 제스처가 오른쪽 클릭의 다른 사용을 방해하는 경우 제어 버튼을 길게 눌러 제스처 메커니즘을 우회할 수 있습니다.
                    자세한 정보 및 영구 솔루션은 다음을 확인하십시오. <a href="#h-t-disable-extension">확장 사용 안 함</a> 절편.
                    </p>
                    <p>
                        아래에는 지원되는 작업과 아이콘이 있습니다. 마우스 콤보로 사용할 수 있는 작업 중 일부와 다음을 사용하여 추가 작업을 사용할 수 있습니다. <a href="#h-t-drag-options">드레그 옵션</a>.
                    </p>
                    <p>
                        원하는 작업을 찾을 수 없는 경우에는 <Link to="/en/contact">요청하기</Link> 그리고 향후 버전에 통합하는 것을 고려할 것입니다.
                    </p>
                    <table>
                        <tr>
                            <td style={{borderWidth: '0'}}>
                                <table style={{verticalAlign:"bottom"}}>
                                    <tr><td>사전 설정된 페이지로 이동</td><td><img src="../assets/go2Site.svg" alt="go to predefine page icon" className="icon" /></td></tr>
                                    <tr><td>사전 설정된 페이지로 이동(새 탭)</td><td><img src="../assets/go2SiteNewTab.svg" alt="go to predefine page  in a new tab icon" className="icon" /></td></tr>
                                    <tr><td>메인 페이지로 이동</td><td><img src="../assets/go2Main.svg" alt="go to the main page icon" className="icon" /></td></tr>
                                    <tr><td>다음 페이지로 이동</td><td><img src="../assets/goForward.svg" alt="go to the next page icon" className="icon" /></td></tr>
                                    <tr><td>이전 페이지로 이동</td><td><img src="../assets/goBack.svg" alt="go to the previous page icon" className="icon" /></td></tr>
                                    <tr><td>7페이지 뒤로 이동 (슈퍼백)</td><td><img src="../assets/goSuperBack.svg" alt="Go 7 pages back, Super back, icon" className="icon" /></td></tr>
                                    <tr><td>페이지 맨 위로 이동</td><td><img src="../assets/goUp.svg" alt="go to the top of the page icon" className="icon" /></td></tr>
                                    <tr><td>페이지 맨 아래로 이동</td><td><img src="../assets/goDown.svg" alt="go to the bottom of the page icon" className="icon" /></td></tr>
                                    <tr><td>이전 보기 탭으로 이동</td><td><img src="../assets/previousViewedTab.svg" alt="go to the previous viewed tab icon" className="icon" /></td></tr>
                                    <tr><td>첫 번째 탭으로 전환</td><td><img src="../assets/go2FirstTab.svg" alt="Switch to the first tab icon" className="icon" /></td></tr>
                                    <tr><td>마지막 탭으로 전환</td><td><img src="../assets/go2LastTab.svg" alt="Switch to the last tab icon" className="icon" /></td></tr>
                                    <tr><td>이전 탭 전환</td><td><img src="../assets/go2PreviousTab.svg" alt="Switch to the previous tab icon" className="icon" /></td></tr>
                                    <tr><td>다음 탭 전환</td><td><img src="../assets/go2NextTab.svg" alt="Switch to the next tab icon" className="icon" /></td></tr>
                                </table>
                            </td>
                            <td style={{borderWidth: '0'}}>
                                <table>
                                    <tr><td>새 탭 열기</td><td><img src="../assets/newTab.svg" alt="open new tab icon" className="icon" /></td></tr>
                                    <tr><td>새 창 열기</td><td><img src="../assets/newWindow.svg" alt="open new window icon" className="icon" /></td></tr>
                                    <tr><td>새 개인 창 열기</td><td><img src="../assets/newPrivateWindow.svg" alt="open new private window icon" className="icon" /></td></tr>
                                    <tr><td>다운로드 열기</td><td><img src="../assets/openDownloads.svg" alt="oOpen Downloads" className="icon" /></td></tr>
                                    <tr><td>닫기 탭</td><td><img src="../assets/closeTab.svg" alt="close tab icon" className="icon" /></td></tr>
                                    <tr><td>오른쪽 탭 닫기</td><td><img src="../assets/closeRightTabs.svg" alt="Close tabs to the right icon" className="icon" /></td></tr>
                                    <tr><td>왼쪽 탭 닫기</td><td><img src="../assets/closeLeftTabs.svg" alt="Close tabs to the left" className="icon" /></td></tr>
                                    <tr><td>이 사이트의 모든 탭 닫기</td><td><img src="../assets/closeSiteTabs.svg" alt="Close all tabs of this site icon" className="icon" /></td></tr>
                                    <tr><td>다른 모든 탭 닫기</td><td><img src="../assets/closeOtherTabs.svg" alt="Close all other tabs icon" className="icon" /></td></tr>
                                    <tr><td>최근에 종료된 복원</td><td><img src="../assets/restore.svg" alt="restore recently closed icon" className="icon" /></td></tr>
                                    <tr><td>음소거 \ 음소거 해제 탭</td><td><img src="../assets/mute.svg" alt="mute icon" className="icon" />&nbsp;<img src="../assets/unmute.svg" alt="unmute icon" className="icon" /></td></tr>
                                    <tr><td>중복 탭</td><td><img src="../assets/duplicateTab.svg" alt="duplicate tab icon" className="icon" /></td></tr>
                                    <tr><td>핀 \ 고정 해제 탭</td><td><img src="../assets/pinTab.svg" alt="pin icon" className="icon" />&nbsp;<img src="../assets/unpinTab.svg" alt="unpin icon" className="icon" /></td></tr>
                                </table>
                            </td>
                            <td style={{borderWidth: '0'}}>
                                <table>
                                    <tr><td>탭 공개 \ 비공개로 만들기</td><td><img src="../assets/public.svg" alt="make tab public icon" className="icon" />&nbsp;<img src="../assets/private.svg" alt="make tab private icon" className="icon" /></td></tr>
                                    <tr><td>탭을 첫 번째 위치로 이동</td><td><img src="../assets/moveTabToStart.svg" alt="Move tab to the first position icon" className="icon" /></td></tr>
                                    <tr><td>탭을 마지막 위치로 이동</td><td><img src="../assets/moveTabToEnd.svg" alt="Move tab to the last position icon" className="icon" /></td></tr>
                                    <tr><td>창 최소화</td><td><img src="../assets/minimize.svg" alt="Minimize window icon" className="icon" /></td></tr>
                                    <tr><td>창 최대화</td><td><img src="../assets/maximize.svg" alt="Maximize window icon" className="icon" />&nbsp;<img src="../assets/unmaximize.svg" alt="unmaximize window icon" className="icon" /></td></tr>
                                    <tr><td>전체화면</td><td><img src="../assets/fullScreen.svg" alt="Full screen icon" className="icon" />&nbsp;<img src="../assets/unfullScreen.svg" alt="unfull screen icon" className="icon" /></td></tr>
                                    <tr><td>창 크기 및 위치 설정</td><td><img src="../assets/setWindowParameters.svg" alt="change window size and location icon" className="icon" /></td></tr>
                                    <tr><td>새로 고침 페이지</td><td><img src="../assets/refresh.svg" alt="refresh icon" className="icon" /></td></tr>
                                    <tr><td>캐시 없이 페이지 새로 고침</td><td><img src="../assets/refreshWithoutCache.svg" alt="Refresh without cache icon" className="icon" /></td></tr>
                                    <tr><td>인쇄 페이지</td><td><img src="../assets/printPage.svg" alt="print page icon" className="icon" /></td></tr>
                                    <tr><td>텍스트 붙여넣기</td><td><img src="../assets/pasteText.svg" alt="paste a text icon" className="icon" /></td></tr>
                                    <tr><td>복사 탭 URL</td><td><img src="../assets/copyURL.svg" alt="copy tab url icon" className="icon" /></td></tr>
                                    <tr><td>확대/축소</td><td><img src="../assets/zoomIn.svg" alt="zoom in icon" className="icon" />&nbsp;<img src="../assets/zoomOut.svg" alt="zoom out icon" className="icon" /></td></tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                    <p>
                        대부분의 조치 선택은 자체 설명이며, 그 중 일부는 추가 설명이 필요합니다.
                        <ul>
                            <li>작업 중 일부는 추가 정보를 제공해야 합니다. 예를 들어 "텍스트 붙여넣기" 작업은 텍스트를 붙여넣고 옵션 링에서 작업을 호버링할 때 표시되는 이름을 입력해야 합니다.</li>
                            <li>"기본 페이지로 이동" 작업은 도메인의 기본 페이지로 이동합니다.</li>
                            <li>새로 고침 페이지 작업은 비개인 모드에서 브라우저에서 새로고침 버튼을 누르는 것과 같습니다.</li>
                            <li>캐시 동작 없이 새로고침 페이지는 다시 모든 페이지 데이터를 다운로드합니다. 이는 개인 모드에서 브라우저에서 새로고침 버튼을 누르는 것과 같습니다.</li>
                            <li>일부 작업은 상태에 따라 다릅니다. 예를 들어 전체 화면 모드에서 전체 화면 작업을 사용하면 전체 화면 모드가 종료됩니다.</li>
                        </ul>
                    </p>    
                </div>
                    <div id="h-t-mouse-combos" className="main-title">
                        <p>
                            마우스콤보
                        </p>
                    </div>
                    <div className="article">        
                        <p>
                            <b>탭 닫기</b> *오른쪽 또는 가운데 마우스 버튼을 두 번 클릭합니다.
                            <br />설정에서 더블 탭에 필요한 속도를 변경할 수 있습니다.
                            <br /><i>*Edge에서는 마우스를 이동하는 동안 더블 태핑 오른쪽 버튼 콤보가 수행되는 경우에만 더블 태핑 중간 버튼 콤보가 정상적으로 작동합니다.</i>
                        </p>
                        <p>
                            <b>새 탭 열기</b> 오른쪽 버튼을 누른 상태에서 왼쪽 버튼을 누르면 됩니다.
                        </p>
                        <p>
                            <b>탭 변경</b> 오른쪽 버튼을 누른 상태에서 휠 버튼을 스크롤합니다.
                            <br />설정에서 탭 전환 방향을 변경할 수 있습니다.
                        </p>
                        <p>
                            <b>페이지 맨 위 또는 맨 아래로 이동</b> 중간 버튼을 누른 상태에서 위 또는 아래로 스크롤하여.
                        </p>
                        <p>
                            <b>확대 또는 축소</b> 중간 버튼을 누른 상태에서 위 또는 아래로 스크롤하여.
                            <br />이 기능은 "페이지 상단 또는 하단으로 이동" 기능과 동일한 콤보를 사용합니다. 이 기능을 사용하려면 설정에서 다른 기능을 교체해야 합니다.
                        </p>
                        <p>
                            설정에서 모든 콤보를 비활성화할 수 있습니다.
                        </p>
                    </div>
                    <div id="h-t-custom-new-tab" className="main-title">
                        <p>
                            사용자 정의 새 탭
                        </p>
                    </div>
                    <div className="article">
                        <img class="h-t-img" src="../assets/starting%20page%20screenshot.png" id="h-t-custom-new-tab-img" alt="custom new tab" width="360px" height="225px" />
                        <p>
                            모든 브라우저 확장은 기본 새 탭 페이지에서 작동할 수 없습니다. 이 문제를 우회하려면 Power Mouse X에서 사용자 지정 새 탭 페이지를 사용합니다.
                        </p>
                        <p>
                            이 사용자 지정 새 탭은 다음 두 가지 방법 중 하나로 열 수 있습니다.<br/>
                            &nbsp;&nbsp;&nbsp;1) 사용합니다. <a href="#h-t-mouse-combos">마우스 콤보</a> 새 탭을 위해<br/>
                            &nbsp;&nbsp;&nbsp;2) "새 탭 열기"를 사용합니다. <a href="#h-t-gestures">실행</a>.
                        </p>
                        위의 방법에서 사용자 정의 새 탭을 열지 않는 경우 "사용자 정의 새 탭 사용" 설정이 켜져 있는지 확인합니다.

                        <p>
                            기본 새 탭 페이지와 마찬가지로 사용자 정의 새 탭에는 검색 상자와 사이트 바로 가기가 표시됩니다. 설정 페이지에서 검색 엔진을 선택하고 검색 상자 아래에 사이트 바로 가기를 구성할 수 있습니다.
                            기본적으로 단축키는 상위 사이트를 위한 것입니다.
                        </p>
                    </div>
                    <div id="h-t-drag-options" className="main-title">
                        <p>
                            드래그 옵션
                        </p>
                    </div>
                    <div className="article">        
                        <div>
                        {<DragOptionsTable v={["선택한 텍스트","링크","이미지"]} h={["복사","웹 검색","S위키백과에서 검색","번역","하이라이트","새 탭에서 열기","새 배경 탭에서 열기","공용/전용 창에서 열기","새 창에서 열기","다운로드"]}/>}
                        <p>
                                웹 페이지 요소를 드래그하면 옵션 링이 열립니다. 요소가 다르기 때문에 다른 옵션이 나타납니다.<br /> 오른쪽 표는 사용 가능한 옵션을 합산한 것입니다.
                            </p>
                        <img class="h-t-img" src="../assets/drag%20ring.jpg" id="h-t-ring-img" alt="drag ring" width="225px" height="225px" style={{float:"none"}} />

                            <p>
                    링이 요소 드래그의 다른 사용을 방해하는 경우 제어 버튼을 길게 눌러 링을 우회할 수 있습니다.
                    자세한 정보 및 영구 솔루션은 다음을 확인하십시오. <a href="#h-t-disable-extension">확장 사용 안 함</a> 절편
                    </p>
                        </div>
                        <div>
                        <p>
                            <i>
                                (1) png 및 svg 이미지만 복사할 수 있습니다.
                            <br />(2) 설정에서 대상 언어를 설정할 수 있습니다.
                            <br />(3) 현재 창 모드에 따라 다릅니다.
                            </i>
                        </p>
                        <p>
                            드래그 옵션은 설정 페이지에서 비활성화할 수 있습니다.
                        </p>
                        </div>
                    </div>
                    <div id="h-t-predictions" className="main-title"><p>예측(Firefox에서는 사용할 수 없음)</p></div>
                    <div className="article">        
                        <p>파워 마우스 X는 다음 페이지를 예측하려고 합니다. 쇼핑 사이트, 검색 엔진, 포럼 등의 사이트를 검색하는 동안 다음 페이지로 이동하기 위해 다음 방법을 사용할 수 있습니다. "앞으로 이동" 제스처를 사용하거나 Alt + →를 누르거나 마우스 앞으로 이동 버튼(일부 마우스에서 사용 가능)을 사용할 수 있습니다. 유튜브에서 이 기능을 사용하면 재생 목록에서 다음 비디오를 볼 수 있습니다.
                        </p>
                        <p>예측은 다른 사이트보다 일부 사이트에서 더 잘 작동하고 때로는 잘 작동하며 때로는 작동하지 않고 결과 목록의 두 번째 페이지에서만 작동하는 경우가 많습니다. 좋아하는 사이트에서 실험해 보세요. </p>
                    </div>
                    <div id="h-t-side-buttons" className="main-title"><p>측면 버튼 구성(Firefox에서는 사용할 수 없음)</p></div>
                    <div className="article">        
                        <p>
                            일부 컴퓨터 마우스에는 일반적으로 빠른 브라우저 앞뒤 탐색에 사용되는 여분의 측면 버튼이 있습니다.
                            파워마우스X를 사용하면 이러한 버튼에 기능을 추가할 수 있습니다.
                            </p>
                            <p> 
                            설정 페이지에서 스크롤 바에서 버튼 누름에서 활성화할 수십 가지 작업 중 하나를 선택합니다. 또한 전진 버튼(버튼 #5) 기본 목표를 선택해야 합니다.<br/>
                            설정을 해주시면. <b>"전진의"</b> 브라우저는 먼저 앞으로 이동을 시도하고 해당 옵션이 없는 경우 작업을 수행합니다. <br/>
                            설정을 해주시면. <b>"전진 예측"</b> 브라우저가 먼저 앞으로 탐색을 시도하고 그러한 옵션이 존재하지 않으면 탐색을 시도합니다. <a href="#h-t-predictions">예견하다</a> 다음 페이지에서, 만약 그가 할 수 없다면, 그는 그 행동을 할 것입니다.<br/>
                            설정을 해주시면. <b>"행동"</b>, 앞으로 버튼을 누르면 동작만 수행되며 앞으로 이동하는 데는 사용되지 않습니다.
                            </p>
                            <p>
                            유료 구독이 있는 경우 기본 마우스 버튼 중 하나를 누른 상태에서 측면 버튼을 누르는 조합에 기능을 추가할 수도 있습니다.
                        </p>
                    </div>
                    <div id="h-t-pin-extension-icon" className="main-title">
                        <p>
                            핀 확장 아이콘
                        </p>
                    </div>
                    <div className="article">  
                        <img class="h-t-img" src="../assets/popup%20menu%20screenshot.png" id="h-t-pop-up-menu-img" alt="pop-up menu" width="200px" height="223px" />
                        <p>
                            확장 설정에 액세스하거나 사이트에서 확장을 일시 중지하는 가장 쉬운 방법은 확장 아이콘을 누를 때 나타나는 팝업 메뉴를 사용하는 것입니다. 도구 모음에 Power Mouse X 아이콘이 나타나지 않으면 다음과 같은 방법으로 수정할 수 있습니다.
                        </p>
                        <p>
                            <b>크롬에서</b> 주소 표시줄 옆에 있는 퍼즐 아이콘을 누릅니다. 그런 다음 확장명 옆에 있는 핀 아이콘을 누릅니다.
                            <br /><b>엣지 내</b> 주소 표시줄 옆에 있는 퍼즐 아이콘을 누릅니다. 그런 다음 확장명 옆에 있는 눈 아이콘을 누릅니다.
                            <br /><b>파이어폭스에서</b> 주소 표시줄 옆에서 마우스 오른쪽 단추를 누른 후 "도구 모음 사용자 지정"을 선택합니다. 그런 다음 확장 아이콘을 도구 모음으로 끕니다.
                        </p>
                    </div>
                    <div id="h-t-disable-extension" className="main-title">
                        <p>
                            확장 사용 안 함
                        </p>
                    </div>
                    <div className="article">        
                        <p>
                        마우스 제스처 또는 드래그 옵션이 일시적으로 방해되는 경우(예: 팝업 메뉴가 나타나지 않도록 함) 마우스를 사용하기 전에 제어 버튼을 길게 눌러 문제를 우회할 수 있습니다.
                        </p>
                        <p>
                        다른 방법으로는 도구 모음(주소 표시줄 옆)에 있는 <img class="h-t-img" src="../assets/icon128.png" alt="Power Mouse X icon" className="icon" /> 눌러 팝업 메뉴를 열고, 그 안의 스위치를 전환하여 현재 사이트 또는 모든 사이트에 대해 확장 프로그램을 비활성화할 수 있습니다.
                            아이콘이 없으면 이전 섹션을 읽으십시오.
                        </p>
                    </div>
                </div>                                
            </>
                    )
                }
//import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
//import '../Site.css';
//import '../Home.css';

export default function Home(){
    document.title = "Power Mouse X - Home";
    return (
        <>
                    <div id="h-buttons">
                <a href="https://chrome.google.com/webstore/detail/power-mouse-x/mnmmbiifajmgbpnbknbhnjclafhijgbn" target="_blank" id="h-chrome">Get it for Chrome</a>
                <a href="https://addons.mozilla.org/en-US/firefox/addon/power-mouse-x" target="_blank" id="h-firefox">Get it for Firefox</a>
                <a href="https://microsoftedge.microsoft.com/addons/detail/mouse-gestures-power-mo/dhooikpjehmdfgkbagkpbeapelphboeg" id="h-edge" target="_blank">Get it for Edge</a>
            </div>
        <div style={{backgroundColor:"#124559"}}>
            <div className="main-container">
            <div className="page-header"><p style={{color:"white"}}>Get Power Mouse X for <span style={{fontWeight: "bold"}}>Free</span></p></div>

            <iframe id="h-youtube" src="https://www.youtube.com/embed/rru95fX3U20" 
                   frameorder='0'
                   allow='autoplay; encrypted-media'
                   allowfullscreen
                   title='video' />
                   <br /><br />&nbsp;
           </div>
        </div>
        <div style={{backgroundColor:"#aec3b0"}}>
        <img className="h-img" src="../assets/gestures.png" alt="gestures" id="h-gestures-img" />
        <div id="h-gestures" className="main-title main-container"><p>Become a power user with Gestures</p></div>
        <div className="article main-container" >
            Go to the previous page<br />
            Go to the next page<br />
            Go to the top of the page<br />
            Go to the bottom of the page<br />
            Go to the main page<br />
            Go to predefine page<br />
            Refresh page<br />
            Mute \ Unmute tab<br />
            Restore recently closed<br />
            and more... <Link to="how%20to#h-t-gestures">Learn more</Link>
        </div>
        </div>
        <div style={{backgroundColor:"#598392"}}>
        <img className="h-img" src="../assets/quick-search-bar-large.png" id="h-quick-bar-img" alt="quick search bar" />
        <div id="h-quick-search" className="main-title main-container"><p>Navigate instantly with the the Quick search bar</p></div>
        <div className="article main-container">
            Don't go to the address bar - let it come to you!
            with the quick search bar you can navigate the web more than twice as fast! And you can even do that in a new tab. <Link to="advance#h-t-quick-search">Learn more</Link> </div>
        </div>
        <div style={{backgroundColor:"#eff6e0"}}>
        <img className="h-img" src="../assets/mouse.png" alt="mouse" id="h-mouse-img" />
        <div id="h-mouse-combos" className="main-title main-container"><p>Do staff the easy way with Mouse combos</p></div>
        <div className="article main-container">
            Close tabs by double clicking the right button, open new tabs by pressing the left button while holding the right button.
            Sounds good? Here are more things you can do with combos: Change tabs, Go to the top or bottom of the page, zoom in or out. <Link to="how%20to#h-t-mouse-combos">Learn more</Link>
        </div>
        </div>
        <div className='main-container'>
        <br /><br /><br /><br />
        <p>
            <a href="https://chrome.google.com/webstore/detail/power-mouse-x/mnmmbiifajmgbpnbknbhnjclafhijgbn">Download Power Mouse X extension for Chrome</a>
        </p>
        <p><a href="https://addons.mozilla.org/en-US/firefox/addon/power-mouse-x">Download Power Mouse X extension for Firefox</a></p>
        <p><a href="https://microsoftedge.microsoft.com/addons/detail/mouse-gestures-power-mo/dhooikpjehmdfgkbagkpbeapelphboeg">Download Power Mouse X extension for Edge</a>
        </p>
        </div>
 </ >   
    )
}

export default function QuickNavItem(props){
    
    const limit = 24;
    function firstLine(s){
        return s.substring(0, s.lastIndexOf(" " , limit))
    }
    function secondLine(s){
        return s.substring(s.lastIndexOf(" " , limit))
    }

    let id = '#h-t-' + props.id;
    let singleLine = props.chapter.length<=limit;
    return(
        <li>
            <a href={id}> {singleLine ? props.chapter : firstLine(props.chapter)} {singleLine ? "" : <><br/>&nbsp;</>} {singleLine ? "" : secondLine(props.chapter)} </a>
        </li>
    )
}
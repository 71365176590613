import { HashLink as Link } from 'react-router-hash-link';
import LangAdvance from '../LangAdvance';

export default function HowTo_es(){
    document.title = "Power Mouse X - How To";
    return (
        <>
        <LangAdvance/>
        <div id="h-t-quick-nav">
        <b>
                    <ul style={{listStyleType:'none', margin: '0 0 0 0', padding: '0 0 0 0'}}>
                        <li><a href="#h-t-quick-search">Barra de búsqueda rápida</a></li>
                    </ul>
                </b>
            </div>
            <div className="main-container">
                <div id="h-t-languages"></div>
                <div className="page-header"><p>Aprenda a utilizar Power Mouse X</p></div>
        
                <div id="h-t-quick-search" className="main-title">
                    <p>Barra de búsqueda rápida</p>
                </div>
                <div className="article">
                    La barra de búsqueda rápida le permite ir instantáneamente a un sitio, buscar en la web o buscar una ficha.
                    Para utilizar el QSB sólo tiene que mantener pulsado el botón derecho del ratón y empezar a escribir.
                    <div className="sub-title">
                        <p>Ir a otro sitio y buscar en la web</p>
                    </div>
                    <p>
                        Puede navegar a un sitio casi instantáneamente utilizando la barra de búsqueda rápida.
                    </p>        
                    Comience a escribir el nombre del sitio (no suelte aún el botón del ratón). La barra de búsqueda rápida (QSB) intentará predecir el sitio que está buscando y lo mostrará. Una vez que la barra predice el sitio correctamente (normalmente después de teclear sólo unos pocos caracteres) puede soltar el botón del ratón y será enviado al sitio.
                    <p>
                    <img class="h-t-img" src="../assets/quick-search-bar.gif" alt="search bar" />
                        <p>
                        Puede utilizar la barra de búsqueda rápida para buscar en la web.
                    Escriba una consulta de búsqueda en lugar del nombre de un sitio, cada vez que la barra de búsqueda no pueda predecir el sitio asumirá que usted quiere buscar en la web y mostrará un símbolo de búsqueda <img class="h-t-img" src="../assets/search.svg" alt="search icon" className="icon" /> a su derecha. 
                    Una vez que suelte el botón del ratón, irá a la página de resultados de búsqueda de un motor de búsqueda.
                    Las personas con suscripción de pago pueden elegir el motor de búsqueda.
                        </p>
                    </p>
                    <div className="sub-title">
                        <p>
                            Buscar una ficha
                        </p>
                    </div>
                    <div>
                        Si tiene muchas pestañas abiertas, la forma más fácil de encontrar y cambiar a otra pestaña puede ser utilizando la barra de búsqueda rápida.
                        Pulse Mayúsculas + Tabulador, Verá un <img class="h-t-img" src="../assets/leftRightArrow.svg" alt="search tab icon" className="icon" />  icono que aparece en la barra indicando "modo pestaña de búsqueda". Si desea cancelarlo, pulse de nuevo Mayúsculas + Tabulador.
                        Puede abrir la barra de búsqueda rápida (QSB) con esta opción activada pulsando Mayús + Tabulador o "/" mientras mantiene pulsado el botón derecho del ratón. Utilizar el botón "/" puede ser más fácil para los zurdos, pero a diferencia de pulsar Mayúsculas + Tabulador, pulsar "/" después de que se haya abierto el QSB no cambiará el estado del "modo de búsqueda de pestañas".
                    </div>
                    <div className="sub-title">
                        <p>
                            Mecanografía con ambas manos
                        </p>
                    </div>
                    Teclear con una sola mano puede resultar incómodo, sobre todo cuando está escribiendo una consulta de búsqueda larga.
                    Para poder escribir con ambas manos, abra la barra de búsqueda rápida manteniendo pulsado el botón derecho del ratón y pulsando la barra espaciadora. Ya puede soltar el botón del ratón y utilizar ambas manos, un símbolo de bloqueo en la barra <img class="h-t-img" src="../assets/lock.svg" alt="lock icon" className="icon" /> indica que ésta permanecerá Pulse la tecla Intro cuando termine de escribir.
                    <div className="sub-title">
                        <p>
                            Abrir un sitio en una nueva pestaña
                        </p>
                    </div>
                    <p>
                        Si desea ir a un sitio o buscar en la web en una nueva pestaña, pulse la tecla Tab.
                        Verá que aparece un signo más <img class="h-t-img" src="../assets/newTab.svg" alt="plus icon" className="icon" /> en la barra que indica "Modo nueva pestaña", lo que significa que los resultados estarán en una nueva pestaña. Si desea cancelar, pulse Tab de nuevo.
                    </p>
                    <p>
                        Puede abrir la barra de búsqueda rápida con esta opción activada pulsando Tabulador o "+" mientras mantiene pulsado el botón derecho del ratón. Utilizar el botón "+" puede resultar más fácil para los zurdos, pero, a diferencia del botón de pestaña, si lo pulsa después de que se haya abierto la barra de búsqueda rápida no cambiará el estado de "Modo de nueva pestaña".
                    </p>
                    <div className="sub-title">
                        <p>
                            Soporte multilingüe
                        </p>
                    </div>
                    <p>
                        Si tiene más de una distribución de teclado, probablemente se habrá encontrado muchas veces escribiendo con la distribución equivocada. ¿No estaría bien poder solucionarlo pulsando un botón? Vaya a la página de configuración y configure sus distribuciones de teclado, la próxima vez que escriba por error en la barra de búsqueda rápida utilizando su 2ª distribución pulse F12 y el texto se transformará mágicamente en el texto correcto como si estuviera escribiendo su 1ª distribución. Vuelva a teclear F12 para transformar el texto a la disposición del 2º teclado.
                    </p>
                    <p>
                    Puede utilizar hasta cuatro trazados, cada pulsación de F12 hará que la extensión intente convertir entre el trazado 1 y los otros trazados, en este orden: 2&#8594;1, 3&#8594;1, 4&#8594;1, 1&#8594;2, 1&#8594;3, 1&#8594;4.
                    </p>
                    <div className="sub-title">
                        <p>
                            Cambiar de opinión
                        </p>
                    </div>
                    En cualquier momento puede cancelar la barra de búsqueda rápida pulsando el botón Escape.
    </div>
                </div>                           
            </>
        )
}
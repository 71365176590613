import { Link, /*useNavigate*/ } from "react-router-dom";
import { useState} from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import CancelBtn from "./CancelBtn";
import { emailPass } from "../Utils.js";

export default function Login(props){
    document.title = "Power Mouse X - Login";
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [checked, setChecked] = useState(false);
    const [failedAuth, setFailedAuth] = useState(false);
    const [animation, setAnimation] = useState(false);
    //const navigate = useNavigate();
    const [loading, setLoading] = useState('');

    const login = async (event) =>{
        event.preventDefault();
        setChecked(true);
        if(emailPass(email)&&passwordPass()){
            event.preventDefault();
            signInWithEmailAndPassword(props.auth, email, password)
            .then((userCredential) => {
                //navigate('/', {replace: true});
              })
              .catch((error) => {
                setAnimation(true);
                setFailedAuth(true);
                let sChar = String.fromCharCode(0xfeff2588);
                for(let i = 0; i < 50 ; i++){
                    setTimeout(() => {
                        setLoading(sChar);
                        sChar+= String.fromCharCode(0xfeff2588);
                    }, i*100);
                }
                setTimeout(() => {
                    setLoading('');
                    setAnimation(false);
                }, 5000);

 
                //to do repalce cancel and login with 3sec animation
                console.log(error.code);
                console.log(error.message);
                const errorCode = error.code;
                const errorMessage = error.message;
              });
        }
    };
    function passwordPass(){
        return password.length>7;
    }

    return(
        <div className="main-container">
            <form id="login-form" className="modal-content animate" onSubmit={login}>
                <div className="container">
                    <h1>Login</h1>
                <br />
                <hr />
                <label htmlFor="email">
                    <b>Email</b>
                </label>
                {(checked && !emailPass(email))?<span className="issue"> - Email not vailed</span> : ""}
                <input type="text" placeholder="Enter Email" name="email" required onChange={(e) => setEmail(e.target.value)}/>
                <label htmlFor="psw">
                    <b>Password</b>
                </label>
                {checked && !passwordPass() ? <span className="issue"> - Password must be at least 8 charcters long</span> : ""}
                <input type="password" id="login-password" placeholder="Enter Password" name="psw" required onChange={(e) => setPassword(e.target.value)}/>
            {animation ?  
        <span>{loading}</span> :
        <>
            <CancelBtn />
            <button type="submit" id="login-submit">
                Login
            </button>
            <span className="psw">
                <Link to='/password recovery' id="forgot-password">Forgot password?</Link>
            </span><br/><br/>
            </>
            }
            {failedAuth && !animation?<span>Wrong email or password</span>:""}
            </div>
        </form>
    </div>
    )
}
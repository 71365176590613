import { Link } from "react-router-dom";

export default function PlanColumn(props){
    const planUrl = props.user ? "/en/buy-plan-"+props.plan : "/en/premium failed";
    
    return (
        <div className="pricing-plan">
            <h2 className="pricing-header">{props.header}</h2>
            <ul className="pricing-features">
                <li className="pricing-features-item">2nd Ring</li>
                <li className="pricing-features-item">Customizable ring color</li>
                <li className="pricing-features-item">Selectable Search Engine</li>
                <li className="pricing-features-item">Unlimited tabs navigation</li>
                <li className="pricing-features-item">{props.browsers} Browsers</li>
                <li className="pricing-features-item">{props.period} auto-renewable subscriptions</li>
            </ul>
            <span className="pricing-price">{props.price}</span>
            <Link to={planUrl} class="pricing-button">GET</Link><br />
            <span className="astrik">{props.astrik}</span> 
      </div>
    )
}
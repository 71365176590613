export default function PremiumFailed(props){
    document.title = "Power Mouse X - Premium Failed";
    return (
        <div className="main-container">
            <h1>
                <p>You must login first</p>
                <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
            </h1>
        </div>
    )
}
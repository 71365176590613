import { Link } from 'react-router-dom';


export default function Footer(){
    return (
        <>
        <br/>&nbsp;
        <br/>&nbsp;
        <br/>&nbsp;
        <br/>&nbsp;
        <br/>&nbsp;
        <br/>&nbsp;
        <br/>&nbsp;
        <br/>&nbsp;
        <br/>&nbsp;
        <br/>&nbsp;
        <footer id="footer">
            <ul>
                <li><a href="/terms and conditions.html"> Terms & conditions</a></li>
                <li><Link to="/en/contact">Contact</Link></li>
            </ul>
        </footer>
        </>
    )
}
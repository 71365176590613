import {Route, Routes, Navigate} from "react-router-dom";
import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore";
import { getAuth,signOut, onAuthStateChanged } from "firebase/auth";
//import './App.css';
import NavBar from './en/NavBar';
import Home from './en/Home';
import HowTo from './en/HowTo';
import Advance from './en/Advance';
import Advance_es from './es/Advance_es';
import Advance_pt_BR from './pt_BR/Advance_pt_BR';
import Advance_hi from './hi/Advance_hi';
import Advance_ko from './ko/Advance_ko';
import HowTo_es from './es/HowTo_es';
import HowTo_ko from './ko/HowTo_ko';
import HowTo_pt_BR from './pt_BR/HowTo_pt_BR';
import HowTo_hi from './hi/HowTo_hi';
import Premium from './en/Premium';
import PremiumFailed from './en/PremiumFailed';
import BuyPlan from './en/BuyPlan';
import Contact from './en/Contact';
import Footer from './Footer';
import Troubleshooting from './en/Troubleshooting';
import SignUp from './en/SignUp';
import Login from './en/Login';
import Profile from './en/Profile';
import Plans from "./en/Plans";
import PostContact from "./en/PostContact";
import PasswordRecovery from './en/PasswordRecovery';
import { useState } from "react";
import './Site.css';
import './NavBar.css';
import './Footer.css';
import ReactGA from 'react-ga4';
import Redirect from "./Redirect";

const app = initializeApp({
  apiKey: "AIzaSyAwT-WNrBHUXPEnU899KhprV_2jdTkT1SY",
  authDomain: "power-mouse-x.firebaseapp.com",
  projectId: "power-mouse-x",
  storageBucket: "power-mouse-x.appspot.com",
  messagingSenderId: "242472948124",
  appId: "1:242472948124:web:f77a0083396f3801107859",
  measurementId: "G-QDQMTZR79N"
});

const auth = getAuth(app);
const db = getFirestore(app);


function App() {
  ReactGA.initialize('G-TNEWDXXWP8');

  const [user, setUser] = useState(null);
  onAuthStateChanged(auth, (user) => {
    if(user != null && !user.emailVerified){
      setUser(null)
    }else{
      setUser(user);
    }
  });
  const logout = async()=>{
    try{
      await signOut(auth);
    }catch(err){
      console.log(err);
    }
  }
  
  return( 
  <div id="layout"> 
    <NavBar user={user} logout={logout} />
    <Routes>
      <Route path='/' element={<Navigate to='/en/' />} />
      <Route path='/en/' element={<Home />} />
      <Route path='/ja/how to' element={<Redirect postfix={"how to.html"}/>} />
      <Route path='/zh_CN/how to' element={<Redirect postfix={"how to.html"}/>} />
      <Route path='/ja/troubleshooting' element={<Redirect postfix={"troubleshooting.html"}/>} />
      <Route path='/ko/troubleshooting' element={<Redirect postfix={"troubleshooting.html"}/>} />
      <Route path='/zh_CN/troubleshooting' element={<Redirect postfix={"troubleshooting.html"}/>} /> 
      <Route path='/es/how to' element={<HowTo_es />} />
      <Route path='/pt_BR/how to' element={<HowTo_pt_BR />} />
      <Route path='/hi/how to' element={<HowTo_hi />} />
      <Route path='/ko/how to' element={<HowTo_ko />} />
      <Route path='/en/how to' element={<HowTo />} />
      <Route path='/ko/how to.html' element={<Navigate to='/ko/how to' />} />
      <Route path='/en/how to.html' element={<Navigate to='/en/how to' />} />
      <Route path='/en/advance' element={<Advance />} />
      <Route path='/es/advance' element={<Advance_es />} />
      <Route path='/pt_BR/advance' element={<Advance_pt_BR />} />
      <Route path='/hi/advance' element={<Advance_hi />} />
      <Route path='/ko/advance' element={<Advance_ko />} />
      <Route path='/zh_CN/advance' element={<Advance />} />
      <Route path='/ja/advance' element={<Advance />} />
      <Route path='en/terms and conditions.html' element={<Navigate to='/terms and conditions.html' />} />
      <Route path='/en/premium' element={<Premium user={user} />} />
      <Route path='/en/premium failed' element={<PremiumFailed />} />
      <Route path='/en/buy-plan-mountly' element={<BuyPlan user={user} db={db} plan={"mountly"}/>} />
      <Route path='/en/buy-plan-trimountly' element={<BuyPlan user={user} db={db} plan={"trimountly"}/>} />
      <Route path='/en/buy-plan-quadmountly' element={<BuyPlan user={user} db={db} plan={"quad mountly"}/>} />
      <Route path='/en/contact' element={<Contact db={db} />} />
      <Route path='/en/contact.html' element={<Navigate to='/en/contact' />} />
      <Route path='/en/post-contact' element={<PostContact />} />
      <Route path='/en/troubleshooting' element={<Troubleshooting />} />
      <Route path='/en/troubleshooting.html' element={<Navigate to='/en/troubleshooting' />} />
      <Route path='/sign up' element={user != null ?<Navigate to='/en/' />
      :<SignUp auth={auth} db={db} />} />
      <Route path='/plans' element={user == null ?<Navigate to='/en/' />
      :<Plans user={user} db={db} />} />
      <Route path='/login' element={user != null ?<Navigate to='/en/' />
      :<Login auth={auth} />} />
      <Route path='/profile' element={user == null ?<Navigate to='/en/' />
      :<Profile user={user} db={db} />} />
      <Route path='/password recovery' element={<PasswordRecovery auth={auth} />} />
    </Routes>
    <Footer />
  </div>
  )
}
export default App;

/*TODO
sign up: 
0fix not recogineing verify user witout refreh

plan:
review securty rules

profile:
0uncommet and fix password change option

!analitics
!multi-lang
*/


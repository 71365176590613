import { Link } from "react-router-dom";
import { createUserWithEmailAndPassword, sendEmailVerification, updateProfile } from "firebase/auth";
import { collection, addDoc, serverTimestamp } from "firebase/firestore"; 
import { doc, setDoc, getFirestore } from "firebase/firestore";
import { useState} from "react";
import CancelBtn from "./CancelBtn";
import { emailPass } from "../Utils.js";

export default function SignUp(props){
    document.title = "Power Mouse X - Sign Up";
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [checked, setChecked] = useState(false);
    const [emailSent, setEmailSent] = useState(false);

    
    const signUp = (event) =>{
        event.preventDefault();
        setChecked(true);
        if(emailPass(email) && passwordPass() && (password === repeatPassword)){
            createUserWithEmailAndPassword(props.auth, email, password)
            .then( credential=>{
                let user = credential.user;
                updateProfile(user, {
                    displayName: firstName
                });
                sendEmailVerification(user);
                setDoc(doc(props.db, "users", user.uid), {
                    accountTimeStamp: serverTimestamp(),
                    firstName: firstName,
                    lastName: lastName,
                    email: email
                });
            }).then(() => {
                setEmailSent(true);
            }).catch((err) => {
                console.log(err);
            });
        }
    }
    function passwordPass(){
        if(password.length<8){return false;}
        if(password=="12345678"){return false;}
        if(password=="123456789"){return false;}
        if(password=="01234567"){return false;}
        if(password=="012345678"){return false;}
        if(password=="0123456789"){return false;}
        for(let i = 1; i<password.length ; i++){
            if(password[i]!=password[0]) {return true;}
        }
        return false;
    }

    return (
        <div className="main-container">
        {emailSent ? 
        <div>
            <h1>
                We sent you a verification email. If don't see it, check your spam folder.
                <br />After pressing the link in the email, referesh this page.
            </h1>
            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
        </div>
        :
        <form id="sign-up-form" onSubmit={signUp}>
            <div className="container">
                <h1>Sign Up</h1>
                <p>Please fill in this form to create an account or <Link to="/login">login</Link> if you already have an account.</p>
                <hr />
                <label htmlFor="first-name"><b>First name</b></label>
                <input type="text" placeholder="Enter first name" id="sign-up-first-name" name="first-name" required onChange={(e) => setFirstName(e.target.value)}/>

                <label htmlFor="last-name"><b>Last name</b></label>
                <input type="text" placeholder="Enter last name" id="sign-up-last-name" name="last-name" required onChange={(e) => setLastName(e.target.value)} />

                <label htmlFor="email"><b>Email</b></label>
                {(checked && !emailPass(email))?<span className="issue"> - Email not vailed</span> : ""}
                <input type="text" placeholder="Enter Email" id="sign-up-email" name="email" required onChange={(e) => setEmail(e.target.value)} />

                <label htmlFor="psw"><b>Password</b></label>
                {checked && !passwordPass() ? 
                    password.length<8 ? 
                    <span className="issue"> - Password must be at least 8 charcters long</span> 
                    : 
                    <span className="issue"> - Password must not be easy to guess</span>
                :
                ""}
                <input type="password" placeholder="Enter Password" name="psw" required onChange={(e) => setPassword(e.target.value)} />

                <label htmlFor="psw-repeat"><b>Repeat Password</b></label>
                {(checked && passwordPass() && password!==repeatPassword)?<span className="issue"> - Password don't match</span>:""}
                <input type="password" placeholder="Repeat Password" id='sign-up-repeat-password' name="psw-repeat" required onChange={(e) => setRepeatPassword(e.target.value)} />

                <p>
                    By creating an account you agree to our <a href="terms and conditions.html" style={{color:"blue"}}>Terms & Conditions</a>.
                </p>
                <div className="clearfix">
                    <CancelBtn />
                    <button type="submit" id="sign-up-submit" className="signup">
                    Sign Up
                    </button>
                </div>
            </div>
        </form>}
        </div>
    )
}
import { HashLink as Link } from 'react-router-hash-link';
import Lang from '../Lang';
import DragOptionsTable from '../DragOptionsTable';

export default function HowTo(){
    document.title = "Power Mouse X - How To";
    return (
        <>
        <Lang/>
            <div id="h-t-quick-nav">
                <b>
                    <ul style={{listStyleType:'none', margin: '0 0 0 0', padding: '0 0 0 0'}}>
                        {/*<li><a href="#h-t-after-installation">After installation</a></li>*/}
                        <li><a href="#h-t-gestures">Gestures</a></li>
                        {/*<li><a href="#h-t-quick-search">Quick search bar</a></li>*/}
                        <li><a href="#h-t-mouse-combos">Mouse combos</a></li>
                        <li><a href="#h-t-custom-new-tab">Custom new tab</a></li>
                        <li><a href="#h-t-drag-options">Drag options</a></li>
                        <li><a href="#h-t-predictions">Predictions</a></li>
                        <li><a href="#h-t-side-buttons">Configure side buttons</a></li>
                        <li><a href="#h-t-pin-extension-icon">Pin extension icon</a></li>
                        <li><a href="#h-t-disable-extension">Disable extension</a></li>
                    </ul>
                </b>
            </div>
            <div className="main-container">
                <div id="h-t-languages"></div>
                <div className="page-header"><p>Learn how to use Power Mouse X</p></div>
        
                {/*<div id="h-t-after-installation" className="main-title"><p>After installation</p></div>*/}
                <div className="article">
                Don't be overwhelmed by the size of this guide, most of it is quite obvious. If you are using a trackpad you may want to check the <a href="#h-t-drag-options">Drag options</a> section first.
                    <p>
                    <mark>Please note that (all) extensions <b>do not work</b> on <b>add-ons download platforms</b> such as the chrome web store and <b>non-URL sites</b> such as the <b>first page</b> when you open a new tab.</mark> See <a href="#h-t-custom-new-tab">Custom new tab</a> section for some solutions.
                    </p>
                </div>
        
                <div id="h-t-gestures" className="main-title">
                    <p>Gestures</p>
                </div>
                <div className="article">
                    <p>
                        Press and hold the right mouse button and drag it to the side to make a “gesture”.
                        A ring will appear around the mouse cursor with eight icons.
                        Place the mouse pointer on the icon representing the action you wish to do then release the mouse button.
                    </p>
                    <p>
                        The default gesture are:
                    </p><img class="h-t-img" src="../assets/ring.jpg" id="h-t-ring-img" alt="ring" width="225px" height="225px" />
                    <div style={{float:"left", marginRight:"32px"}}>
                        <img class="h-t-img arrow-icon" src="../assets/arrow-w.svg" alt="west arrow"/> go to the previous page.<br />
                        <img class="h-t-img arrow-icon" src="../assets/arrow-n.svg" alt="north arrow"/> go to the main page.<br />
                        <img class="h-t-img arrow-icon" src="../assets/arrow-e.svg" alt="east arrow"/> go to the next page.<br />
                        <img class="h-t-img arrow-icon" src="../assets/arrow-s.svg" alt="south arrow"/> refresh page.<br />
                    </div>
                    <div>
                        <img class="h-t-img arrow-icon" src="../assets/arrow-nw.svg" alt="north west arrow"/> go to site: www.Google.com.<br />
                        <img class="h-t-img arrow-icon" src="../assets/arrow-ne.svg" alt="north east arrow"/> go to site: www.Youtube.com.<br />
                        <img class="h-t-img arrow-icon" src="../assets/arrow-se.svg" alt="south east arrow"/> go to site: www.Facebook.com.<br />
                        <img class="h-t-img arrow-icon" src="../assets/arrow-sw.svg" alt="south west arrow"/> go to site: Extension tutorial.<br />
                    </div>
                    <p>
                        The ring is customizable, in the settings menu, you can change \ add \ remove actions, as well as, change their locations. 
                        The ring size is also customizable, we encourage you to make it as small as you are comfortable with.
                    </p>
                    <p>
                    You can activate the ring and choose a gesture without moving the mouse, this is especially useful for people using a touchpad. To do this, press and hold right click, 
                    then press and hold the arrow keys on the keyboard that correspond to the gesture position on the ring (you can press two arrows for gestures positioned in
                     diagonal). Release the right button while still holding the arrow keys.
                    </p>
                    <p>
                    If the mouse gestures interfere with some other use of the right click, you can bypass the gesture mechanism by pressing and holding the control button.
                    For more information and permanent solutions please check the <a href="#h-t-disable-extension">Disable extension</a> section.
                    </p>
                    <p>
                        Below are the supported actions and their icons, note that some of the actions available as mouse combos as well and that additional actions are available using <a href="#h-t-drag-options">drag options</a>.
                    </p>
                    <p>
                        If you can't find here your desired action, you can <Link to="/en/contact">request</Link> it, and we will consider to incorporate it in future versions.
                    </p>
                    <table>
                        <tr>
                            <td style={{borderWidth: '0'}}>
                                <table style={{verticalAlign:"bottom"}}>
                                    <tr><td>Go to predefine page</td><td><img src="../assets/go2Site.svg" alt="go to predefine page icon" className="icon" /></td></tr>
                                    <tr><td>Go to predefine page (new tab)</td><td><img src="../assets/go2SiteNewTab.svg" alt="go to predefine page  in a new tab icon" className="icon" /></td></tr>
                                    <tr><td>Go to the main page</td><td><img src="../assets/go2Main.svg" alt="go to the main page icon" className="icon" /></td></tr>
                                    <tr><td>Go to the next page</td><td><img src="../assets/goForward.svg" alt="go to the next page icon" className="icon" /></td></tr>
                                    <tr><td>Go to the previous page</td><td><img src="../assets/goBack.svg" alt="go to the previous page icon" className="icon" /></td></tr>
                                    <tr><td>Go 7 pages back (Super back)</td><td><img src="../assets/goSuperBack.svg" alt="Go 7 pages back, Super back, icon" className="icon" /></td></tr>
                                    <tr><td>Go to the top of the page</td><td><img src="../assets/goUp.svg" alt="go to the top of the page icon" className="icon" /></td></tr>
                                    <tr><td>Go to the bottom of the page</td><td><img src="../assets/goDown.svg" alt="go to the bottom of the page icon" className="icon" /></td></tr>
                                    <tr><td>Go to the previous viewed tab</td><td><img src="../assets/previousViewedTab.svg" alt="go to the previous viewed tab icon" className="icon" /></td></tr>
                                    <tr><td>Switch to the first tab</td><td><img src="../assets/go2FirstTab.svg" alt="Switch to the first tab icon" className="icon" /></td></tr>
                                    <tr><td>Switch to the last tab</td><td><img src="../assets/go2LastTab.svg" alt="Switch to the last tab icon" className="icon" /></td></tr>
                                    <tr><td>Switch previous tab</td><td><img src="../assets/go2PreviousTab.svg" alt="Switch to the previous tab icon" className="icon" /></td></tr>
                                    <tr><td>Switch next tab</td><td><img src="../assets/go2NextTab.svg" alt="Switch to the next tab icon" className="icon" /></td></tr>
                                </table>
                            </td>
                            <td style={{borderWidth: '0'}}>
                                <table>
                                    <tr><td>Open new tab</td><td><img src="../assets/newTab.svg" alt="open new tab icon" className="icon" /></td></tr>
                                    <tr><td>Open new window</td><td><img src="../assets/newWindow.svg" alt="open new window icon" className="icon" /></td></tr>
                                    <tr><td>Open new private window</td><td><img src="../assets/newPrivateWindow.svg" alt="open new private window icon" className="icon" /></td></tr>
                                    <tr><td>Open Downloads</td><td><img src="../assets/openDownloads.svg" alt="oOpen Downloads" className="icon" /></td></tr>
                                    <tr><td>Close tab</td><td><img src="../assets/closeTab.svg" alt="close tab icon" className="icon" /></td></tr>
                                    <tr><td>Close tabs to the right</td><td><img src="../assets/closeRightTabs.svg" alt="Close tabs to the right icon" className="icon" /></td></tr>
                                    <tr><td>Close tabs to the left</td><td><img src="../assets/closeLeftTabs.svg" alt="Close tabs to the left" className="icon" /></td></tr>
                                    <tr><td>Close all tabs of this site</td><td><img src="../assets/closeSiteTabs.svg" alt="Close all tabs of this site icon" className="icon" /></td></tr>
                                    <tr><td>Close all other tabs</td><td><img src="../assets/closeOtherTabs.svg" alt="Close all other tabs icon" className="icon" /></td></tr>
                                    <tr><td>Restore recently closed</td><td><img src="../assets/restore.svg" alt="restore recently closed icon" className="icon" /></td></tr>
                                    <tr><td>Mute \ Unmute tab</td><td><img src="../assets/mute.svg" alt="mute icon" className="icon" />&nbsp;<img src="../assets/unmute.svg" alt="unmute icon" className="icon" /></td></tr>
                                    <tr><td>Duplicate tab</td><td><img src="../assets/duplicateTab.svg" alt="duplicate tab icon" className="icon" /></td></tr>
                                    <tr><td>Pin \ Unpin tab</td><td><img src="../assets/pinTab.svg" alt="pin icon" className="icon" />&nbsp;<img src="../assets/unpinTab.svg" alt="unpin icon" className="icon" /></td></tr>
                                </table>
                            </td>
                            <td style={{borderWidth: '0'}}>
                                <table>
                                    <tr><td>Make tab public \ private</td><td><img src="../assets/public.svg" alt="make tab public icon" className="icon" />&nbsp;<img src="../assets/private.svg" alt="make tab private icon" className="icon" /></td></tr>
                                    <tr><td>Move tab to the first position</td><td><img src="../assets/moveTabToStart.svg" alt="Move tab to the first position icon" className="icon" /></td></tr>
                                    <tr><td>Move tab to the last position</td><td><img src="../assets/moveTabToEnd.svg" alt="Move tab to the last position icon" className="icon" /></td></tr>
                                    <tr><td>Minimize window</td><td><img src="../assets/minimize.svg" alt="Minimize window icon" className="icon" /></td></tr>
                                    <tr><td>Maximize window</td><td><img src="../assets/maximize.svg" alt="Maximize window icon" className="icon" />&nbsp;<img src="../assets/unmaximize.svg" alt="unmaximize window icon" className="icon" /></td></tr>
                                    <tr><td>Full screen</td><td><img src="../assets/fullScreen.svg" alt="Full screen icon" className="icon" />&nbsp;<img src="../assets/unfullScreen.svg" alt="unfull screen icon" className="icon" /></td></tr>
                                    <tr><td>Set window size and location</td><td><img src="../assets/setWindowParameters.svg" alt="change window size and location icon" className="icon" /></td></tr>
                                    <tr><td>Refresh page</td><td><img src="../assets/refresh.svg" alt="refresh icon" className="icon" /></td></tr>
                                    <tr><td>Refresh page without cache</td><td><img src="../assets/refreshWithoutCache.svg" alt="Refresh without cache icon" className="icon" /></td></tr>
                                    <tr><td>Print page</td><td><img src="../assets/printPage.svg" alt="print page icon" className="icon" /></td></tr>
                                    <tr><td>Paste a text</td><td><img src="../assets/pasteText.svg" alt="paste a text icon" className="icon" /></td></tr>
                                    <tr><td>Copy tab URL</td><td><img src="../assets/copyURL.svg" alt="copy tab url icon" className="icon" /></td></tr>
                                    <tr><td>Zoom in \ out</td><td><img src="../assets/zoomIn.svg" alt="zoom in icon" className="icon" />&nbsp;<img src="../assets/zoomOut.svg" alt="zoom out icon" className="icon" /></td></tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                    <p>
                        Most of the actions choices are self explanatory, a few of them require further clarification:
                        <ul>
                            <li>Some of the actions will require providing additional information, for example the action "Paste a text" requires the text to be pasted and the name that will be displayed when hovering the action on the options ring.</li>
                            <li>The action "Go to the main page" takes you to the main page of the domain.</li>
                            <li>"Refresh page" action is the same as pressing the Reload button on the browser in non-private mode.</li>
                            <li>"Refresh page without cache" action downloads again all the page data, it is the same as pressing the Reload button on the browser in private mode. </li>
                            <li>Some of the actions are state dependent, for example, using Full screen action in full screen mode will exit the full screen mode.</li>
                        </ul>
                    </p>    
                </div>
                    <div id="h-t-mouse-combos" className="main-title">
                        <p>
                            Mouse combos
                        </p>
                    </div>
                    <div className="article">        
                        <p>
                            <b>Close tabs</b> by double clicking the *right or middle mouse button.
                            <br />You can change the speed needed for double tapping in settings.
                            <br /><i>*Please note that on Edge the double tapping right button combo works only if done while moving the mouse, the double tapping middle button combo works normally.</i>
                        </p>
                        <p>
                            <b>Open a new tab</b> by pressing the left button while holding the right button.
                        </p>
                        <p>
                            <b>Change tabs</b> by Scrolling the wheel button while the right button is pressed.
                            <br />You can change the tab switching direction in settings.
                        </p>
                        <p>
                            <b>Go to the top or bottom of the page</b> by scrolling the middle button up or down while it is pressed.
                        </p>
                        <p>
                            <b>Zoom in or out</b> by scrolling the middle button up or down while it is pressed.
                            <br />This feature uses the same combo as the “Go to the top or bottom of the page” feature. To use this feature, you will have to replace the other feature in settings.
                        </p>
                        <p>
                            All combos can be disable in settings.
                        </p>
                    </div>
                    <div id="h-t-custom-new-tab" className="main-title">
                        <p>
                            Custom new tab
                        </p>
                    </div>
                    <div className="article">
                        <img class="h-t-img" src="../assets/starting%20page%20screenshot.png" id="h-t-custom-new-tab-img" alt="custom new tab" width="360px" height="225px" />
                        <p>
                            All browser extensions can't work on the default new tab page. To bypass this issue Power Mouse X uses a custom new tab page.
                        </p>
                        <p>
                            You can open this custom new tab by one of two ways:<br/>
                            &nbsp;&nbsp;&nbsp;1) Use the <a href="#h-t-mouse-combos">mouse combo</a> for a new tab.<br/>
                            &nbsp;&nbsp;&nbsp;2) Use the "open new tab" <a href="#h-t-gestures">gesture</a>.
                        </p>
                        If the above methods do not open a custom new tab, make sure that the setting "Use custom new tab" is on.

                        <p>
                            Like the default new tab page, our custom new tab displays a search box and shortcuts to sites. In the settings page you can choose the search engine and configure the site's shortcuts below the search box.
                            By default the shortcuts are for your top sites.
                        </p>
                    </div>
                    <div id="h-t-drag-options" className="main-title">
                        <p>
                            Drag options
                        </p>
                    </div>
                    <div className="article">        
                        <div>
                        {<DragOptionsTable v={["Selected text","Link","Image"]} h={["Copy","Search the web","Search in Wikipedia","Translate","Highlight","Open in a new tab","Open in a new background tab","Open in a public / private window","Open in a new window","Download"]}/>}
                        <p>
                                Dragging web page elements opens an option ring. Different elements cause different options to appear.<br /> The table to the right sums the available options.
                            </p>
                        <img class="h-t-img" src="../assets/drag%20ring.jpg" id="h-t-ring-img" alt="drag ring" width="225px" height="225px" style={{float:"none"}} />

                            <p>
                    If the ring interferes with some other use of element dragging, you can bypass the ring by pressing and holding the control button.
                    For more information and permanent solutions please check the <a href="#h-t-disable-extension">Disable extension</a> section.
                    </p>
                        </div>
                        <div>
                        <p>
                            <i>
                                (1) Only png and svg images can be copied.
                            <br />(2) You can set the target language in settings.
                            <br />(3) Depending on your current window mode.
                            </i>
                        </p>
                        <p>
                            Drag options can be disabled in the settings page.
                        </p>
                        </div>
                    </div>
                    <div id="h-t-predictions" className="main-title"><p>Predictions (not available on Firefox)</p></div>
                    <div className="article">        
                        <p>Power mouse X tries to predict your next page. While browsing a site such as a shopping site, a search engine, a forum etc, you can use these methods to go to the next page: using the "Go forward" gesture, pressing Alt + &#8594; or using the mouse forward button (available on some mouses). Use this feature on Youtube to watch the next video in the playlist.
                        </p>
                        <p>Predictions work better on some sites more than others, sometimes its works great, sometimes it doesn't work and often it works only from the second page of the list of results. Experiment with it on your favorite sites. </p>
                    </div>
                    <div id="h-t-side-buttons" className="main-title"><p>Configure side buttons (not available on Firefox)</p></div>
                    <div className="article">        
                        <p>
                            Some computer mouses have extra side buttons that are usually used for quick browser back and forward navigation.
                            PowerMouseX allows you to add functionality to these buttons.
                            </p>
                            <p> 
                            In the settings page, choose from the scrollbar one of the dozens of actions you want to activate on a button press. You will also need to choose the forward button (button #5) primary goal:<br/>
                            If you set it on <b>"forward"</b> the browser will first attempt to navigate forward and if such option doesn't exist it will perform the action. <br/>
                            If you set it on <b>"forward & prediction"</b> the browser will first attempt to navigate forward and if such option doesn't exist it will try to <a href="#h-t-predictions">predict</a> the next page, if he can't he will perform the action.<br/>
                            If you set it on <b>"action"</b>, pressing the forward button will only perform the action and will never be used to navigate forward.
                            </p>
                            <p>
                            If you have a paid subscription you can also add functionality to a combination made of pressing side buttons while holding one of the primary mouse buttons.
                        </p>
                    </div>
                    <div id="h-t-pin-extension-icon" className="main-title">
                        <p>
                            Pin extension icon
                        </p>
                    </div>
                    <div className="article">  
                        <img class="h-t-img" src="../assets/popup%20menu%20screenshot.png" id="h-t-pop-up-menu-img" alt="pop-up menu" width="200px" height="223px" />
                        <p>
                            The easiest way to access the extension settings or to pause the extension on a site is via the pop-up menu that appears when pressing the extension icon. If the Power Mouse X icon do not appear in the toolbar you can fix that this way:
                        </p>
                        <p>
                            <b>In Chrome</b> Press the puzzle icon next to the address bar. Then press the pin icon next to the extension name.
                            <br /><b>In Edge</b> Press the puzzle icon next to the address bar. Then press the eye icon next to the extension name.
                            <br /><b>In Firefox</b> Right-click next to the address bar and choose "Customize Toolbar". Then drag the extension icon to the toolbar.
                        </p>
                    </div>
                    <div id="h-t-disable-extension" className="main-title">
                        <p>
                            Disable extension
                        </p>
                    </div>
                    <div className="article">        
                        <p>
                        If the mouse gestures or drag options temporarily interfere, for example by preventing a pop up menu to appear, you can bypass the issue by pressing and holding the control button before using the mouse.
                        </p>
                        <p>
                            Another option is to disable the extension for your current site or for all sites by pressing <img class="h-t-img" src="../assets/icon128.png" alt="Power Mouse X icon" className="icon" /> in the toolbar (next to the address bar) and toggling the switches.                             If the icon is missing, read the previous section.
                        </p>
                    </div>
                </div>                                
            </>
        )
}
import PlanColumn from "./PlanColumn";

export default function Premium(props){
    document.title = "Power Mouse X - Go Premium";
    return (
        <div className="main-container">
            <div className="panel pricing-table">
                <PlanColumn user={props.user} header={"Monthly"} browsers={"4"} period={"Mountly"} price={"2.99$/mount"} plan={"mountly"} />
                <PlanColumn user={props.user} header={"Trimonthly"} browsers={"4"} period={"3 Mounts"} price={"2.49$/mount*"} plan={"trimountly"} astrik={"*You will be charge 7.47$ every 3 mounts."}/>
                <PlanColumn user={props.user} header={"Quad Monthly"} browsers={"6"} period={"4 Mounts"} price={"2.49$/mount**"} plan={"quadmountly"} astrik={"**You will be charge 9.96$ every 4 mounts."}/>
            </div>
        </div>
    )
}
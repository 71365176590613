import { HashLink as Link } from 'react-router-hash-link';
import LangAdvance from '../LangAdvance';

export default function HowTo(){
    document.title = "Power Mouse X - How To";
    return (
        <>
        <LangAdvance/>
            <div id="h-t-quick-nav">
                <b>
                    <ul style={{listStyleType:'none', margin: '0 0 0 0', padding: '0 0 0 0'}}>
                        <li><a href="#h-t-quick-search">Quick search bar</a></li>
                    </ul>
                </b>
            </div>
            <div className="main-container">
                <div id="h-t-languages"></div>
                <div className="page-header"><p>Learn how to use Power Mouse X</p></div>
        
                <div id="quick-search" class="main-title"><p>빠른 검색 바</p></div>
            <div class="article">
                <div class="sub-title"><p>검색란 열기 및 다른 사이트로 이동</p></div>

                <p>빠른 검색 표시줄을 사용하면 거의 즉시 사이트로 이동할 수 있습니다.</p>

                마우스 오른쪽 버튼을 누른 채로 사이트 이름 입력을 시작합니다(아직 마우스 버튼을 놓지 마십시오). 빠른 검색 표시줄이 검색과 함께 나타나고, 찾고 있는 사이트를 예측하여 막대 아래에 표시합니다. 막대가 사이트를 올바르게 예측하면(보통 몇 글자만 입력한 후), 마우스 버튼을 놓으면 사이트로 전송됩니다.
                <p><img src="../assets/search-bar.png" alt="search bar" /></p>

                <div class="sub-title"><p>웹 검색</p></div>

                빠른 검색 표시줄을 사용하여 웹을 검색할 수 있습니다.

                사이트 이름 대신 검색 쿼리를 작성합니다. 검색 표시줄이 사이트를 예측할 수 없는 경우 웹을 검색하여 검색 기호를 표시해야 합니다.<img src="../assets/search.svg" alt="search icon" class="icon" />오른쪽에 있습니다. 마우스 버튼을 놓으면 검색 엔진의 검색 쿼리 결과 페이지로 이동합니다.

                <div class="sub-title"><p>양손으로 타이핑하십시오.</p></div>

                한 손으로 입력하는 것은 불편할 수 있으며, 특히 긴 검색 질문을 작성할 때는 불편할 수 있습니다.

                두 손으로 입력할 수 있도록 마우스 오른쪽 버튼을 누른 상태에서 스페이스 바를 눌러 빠른 검색 바를 엽니다. 마우스 버튼에서 손을 떼고 바의 잠금 기호인 양손을 사용할 수 있습니다.<img src="../assets/lock.svg" alt="lock icon" class="icon" />막대가 유지됨을 나타냅니다. 입력이 끝나면 Enter 키를 누릅니다.

                <div class="sub-title"><p>새 탭에서 사이트 열기</p></div>

                <p>사이트로 이동하거나 새 탭에서 웹을 검색하려면 Tab 키를 누릅니다.

                    플러스 기호가 표시됩니다.<img src="../assets/newTab.svg" alt="plus icon" class="icon" />"새 탭 모드"를 나타내는 막대에 나타납니다. 즉, 결과가 새 탭에 있음을 의미합니다. 취소하려면 Tab을 다시 누르십시오.
                </p>
                <p>마우스 오른쪽 버튼을 누른 상태에서 Tab 또는 "+"를 눌러 이 설정을 사용하여 빠른 검색 표시줄을 열 수 있습니다.
                    왼손잡이에게는 "+" 단추를 사용하는 것이 더 쉬울 수 있지만, Tab 단추와 달리 빠른 검색 표시줄이 열린 후에 누르면 "새 탭 모드" 상태가 변경되지 않습니다.
                </p>

                <div class="sub-title"><p>다국어 지원</p></div>
                <p>키보드 레이아웃이 여러 개 있는 경우 잘못된 레이아웃을 사용하여 여러 번 입력할 수 있습니다. 버튼 하나로 고칠 수 있으면 좋지 않을까요? 설정 페이지로 이동하여 키보드 레이아웃을 설정합니다.다음에는 두 번째 레이아웃을 사용하여 퀵 검색 바에 잘못 입력하면 첫 번째 레이아웃을 입력하는 것처럼 텍스트가 올바른 텍스트로 마법처럼 변환됩니다. 텍스트를 두 번째 키보드 레이아웃으로 변환하려면 F12를 다시 입력합니다.</p>
                <p>최대 4개의 레이아웃을 사용할 수 있습니다. 각 F12를 누를 때마다 확장이 레이아웃 1과 다른 레이아웃 간에 2→1, 3→1, 4→1, 1→2, 1→3, 1→4의 순서로 변환을 시도합니다.</p>

                <div class="sub-title"><p>마음을 바꾸다</p></div>

                언제든지 이스케이프 버튼을 누르면 빠른 검색 표시줄을 취소할 수 있습니다.

            </div>
                </div>                                
            </>
        )
}
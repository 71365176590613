import { HashLink as Link } from 'react-router-hash-link';
import Lang from '../Lang';
import QuickNav from '../QuickNav';

//ver 240121
//otão >botão 377

export default function HowTo_pt_BR(){
    document.title = "Power Mouse X - Instruções de uso";
    return (
        <>
        <Lang/>
        <QuickNav chapters={["Após a instalação","Gestos","Atalhos do mouse","Nova aba personalizada","Opções de arrastar","Previsão","Configurar botões laterais","Ícone para fixar extensão","Desativar extensão"]}/>

            <div className="main-container">
                <div id="h-t-languages"></div>
                <div className="page-header"><p>Aprenda a usar o Power Mouse X</p></div>
        
                <div id="h-t-after-installation" className="main-title"><p>Após a instalação</p></div>
                <div className="article">
                Após a instalação, a extensão será ativada em abas recém-criadas, mas talvez não funcione em abas que já estavam abertas. <mark>Você precisa recarregar as abas que estavam previamente abertas.
                É importante mencionar que (todas) as extensões <b>não são compatíveis</b> com <b>plataformas de download de complementos,</b> como a Chrome Web Store, <b>e em sites sem URL,</b> como a <b>primeira página</b> ao abrir uma nova aba.</mark> Consulte a seção sobre <a href="#h-t-custom-new-tab">personalização de novas</a> abas para encontrar alternativas.
        
                    <p>
                    Este manual pode parecer extenso, mas a maioria dos recursos são bem intuitivos. Se estiver usando um trackpad, então recomendamos consultar a seção de <a href="#h-t-drag-options">Opções de arrastar</a> primeiro.
                    </p>
                </div>
        
                <div id="h-t-gestures" className="main-title">
                    <p>Gestos</p>
                </div>
                <div className="article">
                    <p>
                        Para executar um gesto, mantenha pressionado o botão direito do mouse e arraste-o para o lado.
                        Um círculo surgirá ao redor do cursor com oito símbolos.
                        Aponte o cursor para o símbolo que corresponde à ação desejada e solte o botão.
                    </p>
                    <p>
                        Os gestos padrão são:
                    </p><img class="h-t-img" src="../assets/ring.jpg" id="h-t-ring-img" alt="ring" width="225px" height="225px" />
                    <div style={{float:"left", marginRight:"32px"}}>
                        <img class="h-t-img arrow-icon" src="../assets/arrow-w.svg" alt="west arrow"/> ir para a página anterior.<br />
                        <img class="h-t-img arrow-icon" src="../assets/arrow-n.svg" alt="north arrow"/> ir para a página principal.<br />
                        <img class="h-t-img arrow-icon" src="../assets/arrow-e.svg" alt="east arrow"/> ir para a próxima página.<br />
                        <img class="h-t-img arrow-icon" src="../assets/arrow-s.svg" alt="south arrow"/> atualizar a página.<br />
                    </div>
                    <div>
                        <img class="h-t-img arrow-icon" src="../assets/arrow-nw.svg" alt="north west arrow"/> acessar a página: www.Google.com.<br />
                        <img class="h-t-img arrow-icon" src="../assets/arrow-ne.svg" alt="north east arrow"/> acessar a página: www.Youtube.com.<br />
                        <img class="h-t-img arrow-icon" src="../assets/arrow-se.svg" alt="south east arrow"/> acessar a página: www.Facebook.com.<br />
                        <img class="h-t-img arrow-icon" src="../assets/arrow-sw.svg" alt="south west arrow"/> acessar a página: Tutorial da extensão.<br />
                    </div>
                    <p>
                        Você pode personalizar o círculo de comandos. No painel de ajustes, é possível modificar, acrescentar ou excluir funções, além de reposicionar os ícones. O tamanho do anel também é ajustável, recomendamos que use o menor possível para trazer mais conforto.
                    </p>
                    <p>
                    Você pode ativar o anel e escolher um gesto sem mover o mouse, o que é bem útil para quem usa touchpad. Para fazer isso, pressione o botão direito do mouse e, ao mesmo tempo, pressione as setas do teclado que representam a localização do gesto no círculo (você pode pressionar duas setas para representar uma direção diagonal).
                    Solte o botão direito enquanto mantém as teclas de seta pressionadas.
                    </p>
                    <p>
                    Caso os gestos do mouse interfiram com outras funções do clique direito, é possível desativar temporariamente essa funcionalidade ao manter a tecla Control pressionada.
                    Para saber mais sobre informações e outras soluções, consulte a seção <a href="#h-t-disable-extension">Desativar extensão</a>.
                    </p>
                    <p>
                        As ações disponíveis e seus respectivos ícones estão listados a seguir. Vale ressaltar que determinadas funcionalidades também podem ser executadas através de combinações de teclas do mouse, e funcionalidades adicionais estão disponíveis nas <a href="#h-t-drag-options">opções de arrastar.</a>.
                    </p>
                    <p>
                    Caso a ação que procura não esteja listada, faça uma <Link to="/en/contact">solicitação</Link> e consideraremos incorporá-la em atualizações futuras.
                    </p>
                    <table>
                        <tr>
                            <td style={{borderWidth: '0'}}>
                                <table style={{verticalAlign:"bottom"}}>
                                    <tr><td>Ir para a página de<br />predefinição</td><td><img src="../assets/go2Site.svg" alt="go to predefine page icon" className="icon" /></td></tr>
                                    <tr><td>Ir para a página principal</td><td><img src="../assets/go2Main.svg" alt="go to the main page icon" className="icon" /></td></tr>
                                    <tr><td>Ir para a próxima página</td><td><img src="../assets/goForward.svg" alt="go to the next page icon" className="icon" /></td></tr>
                                    <tr><td>Ir para a página anterior</td><td><img src="../assets/goBack.svg" alt="go to the previous page icon" className="icon" /></td></tr>
                                    <tr><td>Voltar 7 páginas (Retorno rápido)</td><td><img src="../assets/goSuperBack.svg" alt="Go 7 pages back, Super back, icon" className="icon" /></td></tr>
                                    <tr><td>Ir para o topo da página</td><td><img src="../assets/goUp.svg" alt="go to the top of the page icon" className="icon" /></td></tr>
                                    <tr><td>Ir para o final da página</td><td><img src="../assets/goDown.svg" alt="go to the bottom of the page icon" className="icon" /></td></tr>
                                    <tr><td>Ir para a aba visualizada anteriormente</td><td><img src="../assets/previousViewedTab.svg" alt="go to the previous viewed tab icon" className="icon" /></td></tr>
                                    <tr><td>Alternar para a primeira aba</td><td><img src="../assets/go2FirstTab.svg" alt="Switch to the first tab icon" className="icon" /></td></tr>
                                    <tr><td>Alternar para a última aba</td><td><img src="../assets/go2LastTab.svg" alt="Switch to the last tab icon" className="icon" /></td></tr>
                                    <tr><td>Navegar para a aba anterior</td><td><img src="../assets/go2PreviousTab.svg" alt="Switch to the previous tab icon" className="icon" /></td></tr>
                                    <tr><td>Navegar para a próxima aba</td><td><img src="../assets/go2NextTab.svg" alt="Switch to the next tab icon" className="icon" /></td></tr>
                                    <tr><td>Abrir nova aba</td><td><img src="../assets/newTab.svg" alt="open new tab icon" className="icon" /></td></tr>
                                </table>
                            </td>
                            <td style={{borderWidth: '0'}}>
                                <table>
                                    <tr><td>Abrir nova janela<br />&nbsp;</td><td style={{minWidth: '52px'}}><img src="../assets/newWindow.svg" alt="open new window icon" className="icon" /></td></tr>
                                    <tr><td>Abrir nova janela privativa</td><td><img src="../assets/newPrivateWindow.svg" alt="open new private window icon" className="icon" /></td></tr>
                                    <tr><td>Fechar aba</td><td><img src="../assets/closeTab.svg" alt="close tab icon" className="icon" /></td></tr>
                                    <tr><td>Fechar abas à direita</td><td><img src="../assets/closeRightTabs.svg" alt="Close tabs to the right icon" className="icon" /></td></tr>
                                    <tr><td>Fechar abas à esquerda</td><td><img src="../assets/closeLeftTabs.svg" alt="Close tabs to the left" className="icon" /></td></tr>
                                    <tr><td>Fechar todas as abas deste site</td><td><img src="../assets/closeSiteTabs.svg" alt="Close all tabs of this site icon" className="icon" /></td></tr>
                                    <tr><td>Fechar todas as outras abas</td><td><img src="../assets/closeOtherTabs.svg" alt="Close all other tabs icon" className="icon" /></td></tr>
                                    <tr><td>Restaurar aba fechada recentemente</td><td><img src="../assets/restore.svg" alt="restore recently closed icon" className="icon" /></td></tr>
                                    <tr><td>Silenciar / Dessilenciar aba</td><td><img src="../assets/mute.svg" alt="mute icon" className="icon" />&nbsp;<img src="../assets/unmute.svg" alt="unmute icon" className="icon" /></td></tr>
                                    <tr><td>Duplicar aba</td><td><img src="../assets/duplicateTab.svg" alt="duplicate tab icon" className="icon" /></td></tr>
                                    <tr><td>Fixar / Desafixar aba</td><td><img src="../assets/pinTab.svg" alt="pin icon" className="icon" />&nbsp;<img src="../assets/unpinTab.svg" alt="unpin icon" className="icon" /></td></tr>
                                    <tr><td>Tornar aba pública / privada</td><td><img src="../assets/public.svg" alt="make tab public icon" className="icon" />&nbsp;<img src="../assets/private.svg" alt="make tab private icon" className="icon" /></td></tr>
                                    <tr><td>&nbsp;</td><td>&nbsp;</td></tr>
                                </table>
                            </td>
                            <td style={{borderWidth: '0'}}>
                                <table>
                                    <tr><td>Mover aba para a primeira <br />posição</td><td style={{minWidth: '52px'}}><img src="../assets/moveTabToStart.svg" alt="Move tab to the first position icon" className="icon" /></td></tr>
                                    <tr><td>Mover aba para a última posição</td><td><img src="../assets/moveTabToEnd.svg" alt="Move tab to the last position icon" className="icon" /></td></tr>
                                    <tr><td>Minimizar janela</td><td><img src="../assets/minimize.svg" alt="Minimize window icon" className="icon" /></td></tr>
                                    <tr><td>Maximizar janela</td><td><img src="../assets/maximize.svg" alt="Maximize window icon" className="icon" />&nbsp;<img src="../assets/unmaximize.svg" alt="unmaximize window icon" className="icon" /></td></tr>
                                    <tr><td>Tela cheia</td><td><img src="../assets/fullScreen.svg" alt="Full screen icon" className="icon" />&nbsp;<img src="../assets/unfullScreen.svg" alt="unfull screen icon" className="icon" /></td></tr>
                                    <tr><td>Ajustar dimensões e posição da janela</td><td><img src="../assets/setWindowParameters.svg" alt="change window size and location icon" className="icon" /></td></tr>
                                    <tr><td>Atualizar página</td><td><img src="../assets/refresh.svg" alt="refresh icon" className="icon" /></td></tr>
                                    <tr><td>Atualizar página sem cache</td><td><img src="../assets/refreshWithoutCache.svg" alt="Refresh without cache icon" className="icon" /></td></tr>
                                    <tr><td>Imprimir página</td><td><img src="../assets/printPage.svg" alt="print page icon" className="icon" /></td></tr>
                                    <tr><td>Colar um texto</td><td><img src="../assets/pasteText.svg" alt="paste a text icon" className="icon" /></td></tr>
                                    <tr><td>Copiar URL da aba</td><td><img src="../assets/copyURL.svg" alt="copy tab url icon" className="icon" /></td></tr>
                                    <tr><td>Aumentar / Diminuir zoom</td><td><img src="../assets/zoomIn.svg" alt="zoom in icon" className="icon" />&nbsp;<img src="../assets/zoomOut.svg" alt="zoom out icon" className="icon" /></td></tr>
                                    <tr><td>&nbsp;</td><td>&nbsp;</td></tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                    <p>
                        A maioria das funcionalidades são intuitivas, mas algumas precisam de mais explicações:
                    <ul>
                            <li>Algumas ações precisam de informações complementares, por exemplo, "Colar um texto" necessita do texto a ser colado e do nome a ser exibido ao passar o cursor sobre a ação no anel de opções.</li>
                            <li>A ação "Ir para a página principal" leva à página principal do domínio.</li>
                            <li>"Atualizar página" é equivalente a pressionar o botão Recarregar no navegador.</li>
                            <li>"Atualizar página sem cache" baixa novamente todos os dados da página, similar a pressionar o botão Recarregar no navegador em modo privado.</li>
                            <li>Algumas funcionalidades dependem do estado atual, como por exemplo, ativar o modo de tela cheia quando já se está nesse modo resultará na saída do mesmo.</li>
                        </ul>
                    </p>    
                </div>

                {/*<div id="h-t-quick-search" className="main-title">
                    <p>Barra de pesquisa rápida.</p>
                </div>
                <div className="article">
                    A barra de pesquisa rápida permite acesso fácil a diversos recursos, como um site, uma pesquisa na web ou até mesmo uma pesquisa pelas suas abas.
                    Para usar a Barra de Pesquisa Rápida, basta segurar o botão direito do mouse e começar a digitar.
                    <div className="sub-title">
                        <p>Acessar outro site ou pesquisar na internet</p>
                    </div>
                    <p>
                        É possível acessar um site rapidamente ao usar a barra de pesquisa rápida.
                    </p>        
                    Para começar, digite o nome do site (mantenha o botão do mouse pressionado). A BPR tentará identificar o site desejado e irá exibi-lo. Basta digitar poucos caracteres e a barra já identifica corretamente o site desejado, e depois é só liberar o botão do mouse e você será direcionado automaticamente ao site.
                    <p>
                    <img class="h-t-img" src="../assets/quick-search-bar.gif" alt="search bar" />
                        <p>
                        Você pode uisar a Barra de pesquisa rápida para fazer buscas na internet.
                        Ao invés de digitar o endereço de um site, digite o que deseja procurar. Se a barra não identificar automaticamente o site, ela ativará a opção de pesquisa na internet, exibindo o seguinte ícone de pesquisa <img class="h-t-img" src="../assets/search.svg" alt="search icon" className="icon" /> à direita. Ao soltar o botão do mouse, a página de resultados de um mecanismo de busca será exibida.
                    Assinantes têm a opção de selecionar o serviço de busca que será utilizado.
                        </p>
                    </p>
                    <div className="sub-title">
                        <p>
                            Pesquisar em abas
                        </p>
                    </div>
                    <div>
                        Você pode usar a barra de pesquisa rápida para identificar uma aba em meio a diversas abas abertas.
                        Pressione Shift + Tab. O seguinte ícone <img class="h-t-img" src="../assets/leftRightArrow.svg" alt="search tab icon" className="icon" />  que indica "modo de busca de aba" aparecerá na barra. Para cancelar, pressione Shift + Tab novamente.
                        Você pode abrir a BPR com essa configuração ativada ao pressionar Shift + Tab ou "/" enquanto segura o botão direito do mouse. 
                        Para canhotos, a tecla "/" pode ser mais cômoda, mas seu uso não altera a condição de "modo de busca de aba".
                    </div>
                    <div className="sub-title">
                        <p>
                            Digitação com ambas as mãos
                        </p>
                    </div>
                    Digitar com uma mão pode ser desconfortável, especialmente se você precisa escrever uma frase muito longa.
                    Para digitar com as duas mãos, abra a barra de pesquisa rápida ao segurar o botão direito do mouse e pressionando a barra de espaço. Isso permite que você solte o mouse e utilize ambas as mãos para digitar, que é indicado pelo ícone de cadeado <img class="h-t-img" src="../assets/lock.svg" alt="lock icon" className="icon" /> na barra. Pressione a tecla Enter quando terminar de digitar.
                    <div className="sub-title">
                        <p>
                            Abrir um site em uma nova aba
                        </p>
                    </div>
                    <p>
                        Para navegar até um site ou realizar uma busca na internet em uma aba separada, utilize a tecla Tab.
                        Isso ativará o ícone de adição, <img class="h-t-img" src="../assets/newTab.svg" alt="plus icon" className="icon" /> indicando que o "modo de nova aba" está ativado, e os resultados serão abertos em uma nova aba. Para desativar, basta pressionar Tab novamente.
                    </p>
                    <p>
                        A barra de pesquisa rápida pode ser acionada neste modo ao usar a tecla Tab ou "+", e manter pressionado o botão direito do mouse.Para canhotos, a tecla "+" é uma alternativa conveniente, mas seu uso não altera a condição de "Modo de nova aba".
                    </p>
                    <div className="sub-title">
                        <p>
                            Suporte a diversos idiomas
                        </p>
                    </div>
                    <p>
                        Se você trabalha com diferentes configurações de teclado, é comum ocorrer erros de digitação devido ao uso do layout incorreto. Seria ótimo resolver isso com um botão, não é? Acesse a página de configurações e defina seus layouts de teclado. Se digitar por engano na barra de pesquisa rápida usando seu segundo layout, pressione F12 e o texto será corrigido para o texto correto num passe de mágica, como se estivesse digitando no layout correto o tempo inteiro. 
                        Digite F12 novamente para transformar o texto para o segundo layout de teclado.
                    </p>
                    <p>
                    Você pode usar até quatro layouts. Ao pressionar F12, a extensão tentará converter entre o layout 1 e os outros layouts, na seguinte ordem: 2&#8594;1, 3&#8594;1, 4&#8594;1, 1&#8594;2, 1&#8594;3, 1&#8594;4.
                    </p>
                    <div className="sub-title">
                        <p>
                            Mudando de ideia
                        </p>
                    </div>
                    Se desejar desativar a barra de pesquisa rápida, basta pressionar a tecla Escape.
    </div>*/}
                    <div id="h-t-mouse-combos" className="main-title">
                        <p>
                            Atalhos do mouse
                        </p>
                    </div>
                    <div className="article">        
                        <p>
                            <b>Para fechar abas,</b> clique duas vezes com o botão direito* ou do meio do mouse.
                            <br />Você pode alterar a velocidade necessária para o duplo clique nas configurações.
                            <br /><i>*Importante: no navegador Edge, o duplo clique com o botão direito requer movimento do mouse para funcionar, mas o atalho com o botão central funciona normalmente.</i>
                        </p>
                        <p>
                            <b>Para abrir uma nova aba,</b> pressione o botão esquerdo enquanto segura o botão direito.
                        </p>
                        <p>
                            <b>Para alternar abas,</b> utilize a roda do mouse e pressione simultaneamente o botão direito.
                            <br />Você pode mudar a direção da troca de abas nas configurações.
                        </p>
                        <p>
                            <b>Vá para o topo ou final da página</b> ao rolar a roda do mouse para cima ou para baixo, enquanto o botão central estiver pressionado.
                        </p>
                        <p>
                            <b>Para aumentar ou diminuir o zoom,</b> role a roda do mouse para cima ou para baixo, enquanto o botão estiver pressionado.
                            <br />Este recurso usa o mesmo atalho da função “Ir para o topo ou final da página”. Para usar este recurso, você terá que escolher qual funcionalidade irá usá-lo nas configurações.
                        </p>
                        <p>
                            Todos os atalhos podem ser desativados nas configurações.
                        </p>
                    </div>
                    <div id="h-t-custom-new-tab" className="main-title">
                        <p>
                            Nova aba personalizada
                        </p>
                    </div>
                    <div className="article">
                        <img class="h-t-img" src="../assets/starting%20page%20screenshot.png" id="h-t-custom-new-tab-img" alt="custom new tab" width="360px" height="225px" />
                        <p>
                            Nenhuma extensão funciona na aba inicial padrão do navegador. Para solucionar esse problema, o Power Mouse X cria uma aba inicial própria.
                        </p>
                        <p>
                            Semelhante à aba padrão, essa aba personalizada apresenta atalhos para sites. Você pode desativá-los ou configurá-los na página de configurações.
                        </p>
                        <p>
                            Existem duas formas de acessar a aba personalizada:<br/>
                            1) Use o <a href="#h-t-mouse-combos">atalho do mouse</a> para abrir uma nova aba. Uma nova aba personalizada será aberta, a menos que você tenha alterado a configuração para abrir uma nova aba padrão.<br/>
                            2) Se você não quiser usar o atalho ou se estiver usando um trackpad, é possível definir a nova aba personalizada do Power Mouse X como sua aba padrão. Para isso, você deve baixar um complemento adicional chamado "Power Mouse X New tab replacement".
                            Você pode reverter essa mudança ao desativar o complemento adicional.<br />
                            Importante: vale ressaltar que a aba padrão ainda será utilizada no modo anônimo dos navegadores Chrome e Edge.
                        </p>
                        Faça o download do complemento Power Mouse X New tab replacement para:<br/>
                        <a href="https://chrome.google.com/webstore/detail/power-mouse-x-new-tab-rep/lokfekmibhakginjdmfmiafjnocinefd?hl=en-GB&authuser=0">Chrome</a>&nbsp; 
                        <a href="https://microsoftedge.microsoft.com/addons/detail/power-mouse-x-new-tab-rep/cmapphcgabfcjcnehjchcmgodpmalafg">Edge</a>&nbsp;
                        <a href="https://addons.mozilla.org/en-US/firefox/addon/powermousex-newtab-replacement/">Firefox</a>
                    </div>
                    <div id="h-t-drag-options" className="main-title">
                        <p>
                            Opções de arrastar
                        </p>
                    </div>
                    <div className="article">        
                        <div>
                        <table id="h-t-options-table" style={{float:"right", marginLeft:"24px"}}>
                            <tr>
                                <td></td><td><b>Texto selecionado</b></td><td><b>Link</b></td><td><b>Imagem</b></td>
                            </tr>
                            <tr>
                                <td><b>Copiar<sup>(1)</sup></b></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td><td></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td>
                            </tr>
                            <tr>
                                <td><b>Pesquisar na internet</b></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td>
                            </tr>
                            <tr>
                                <td><b>Pesquisar na Wikipedia<sup>(2)</sup></b></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td><td></td><td></td>
                            </tr>
                            <tr>
                                <td><b>Traduzir<sup>(2)</sup></b></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td><td></td><td></td>
                            </tr>
                            <tr>
                                <td><b>Destacar</b></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td><td></td><td></td>
                            </tr>
                            <tr>
                                <td><b>Abrir em uma nova aba</b></td><td></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td>
                            </tr>
                            <tr>
                                <td><b>Abrir em uma nova aba em segundo plano</b></td><td></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td>
                            </tr>
                            <tr>
                                <td><b>Abrir em uma janela pública/privada<sup>(3)</sup></b></td><td></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td>
                            </tr>
                            <tr>
                                <td><b>Abrir em uma nova janela</b></td><td></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td>
                            </tr>                            
                            <tr>
                                <td><b>Baixar</b></td><td></td><td></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td>
                            </tr>
                        </table>
                        <p>
                        Ao arrastar elementos de uma página, um círculo de opções será exibido. Cada elemento gera opções distintas.<br /> A tabela à direita mostra as opções que estão disponíveis.
                            </p>
                        <img class="h-t-img" src="../assets/drag%20ring.jpg" id="h-t-ring-img" alt="drag ring" width="225px" height="225px" style={{float:"none"}} />

                            <p>
                    Se o círculo de opções interferir em outras funcionalidades do tipo arrastar, é possível desativá-lo ao manter o botão Control pressionado.
                    Para saber mais sobre informações e outras soluções, consulte a seção <a href="#h-t-disable-extension">Desativar extensão</a>.
                    </p>
                        </div>
                        <div>
                        <p>
                            <i>
                            (1) Apenas imagens em formato png podem ser copiadas.
                            <br />(2) Você pode definir o idioma alvo nas configurações.
                            <br />(3) Varia conforme o tipo de janela em uso.
                            </i>
                        </p>
                        <p>
                            As opções de arrastar podem ser desativadas na página de configurações.
                        </p>
                        </div>
                    </div>
                    <div id="h-t-predictions" className="main-title"><p>Previsão (incompatível com o Firefox)</p></div>
                    <div className="article">        
                        <p>O Power Mouse X tenta prever a próxima página que você irá usar. 
                        Para sites variados, como lojas virtuais, buscadores e fóruns, você pode avançar ao utilizar um dos seguintes métodos: atalho para "Avançar", tecla Alt + &#8594;, ou usando o botão avançar do mouse (disponível em alguns mouses).
                            Este recurso é útil para o Youtube, por exemplo, onde você pode avançar para o próximo vídeo da playlist.
                        </p>
                        <p>A eficácia das previsões varia de acordo com o site: em alguns é mais precisa, em outros menos, e frequentemente é mais efetiva a partir da segunda página de resultados. Experimente utilizar em seus sites favoritos. </p>
                    </div>
                    <div id="h-t-side-buttons" className="main-title"><p>Configurar botões laterais (incompatível com o Firefox)</p></div>
                    <div className="article">        
                        <p>
                            Mouses modernos frequentemente possuem botões adicionais nas laterais, comumente empregados para servir de atalho para retroceder ou avançar no navegador.
                            O PowerMouseX expande as possibilidades desses botões.
                            </p>
                            <p> 
                            Na interface de configurações, selecione um entre os várias recursos disponíveis
                            que será ativado com o clique de um botão. Você também precisará escolher o objetivo principal do botão avançar (botão nº 5):<br/>
                            Se definido como <b>"avançar,"</b> o navegador tentará primeiro navegar para a próxima página e, se isso não for possível, executará a ação. <br/>
                            Se definido como <b>"avançar e previsão,"</b> o navegador tentará primeiro avançar e, se não houver tal opção, tentará <a href="#h-t-predictions">prever</a> a próxima página.<br/>
                            Se definido como <b>"ação,"</b>o botão de avançar se dedicará exclusivamente à função escolhida, e não funcionará mais para avançar.
                            </p>
                            <p>
                            Assinantes podem programar funções avançadas de atalhos que envolvem os botões laterais e principais.
                        </p>
                    </div>
                    <div id="h-t-pin-extension-icon" className="main-title">
                        <p>
                            Ícone para fixar extensão
                        </p>
                    </div>
                    <div className="article">  
                        <img class="h-t-img" src="../assets/popup%20menu%20screenshot.png" id="h-t-pop-up-menu-img" alt="pop-up menu" width="200px" height="223px" />
                        <p>
                            Para acessar as configurações do complemento ou para suspender seu uso em um site específico de maneira mais prática, basta acessar o menu pop-up que aparece ao pressionar o ícone da extensão. Basta seguir este procedimento para exibir o ícone do Power Mouse X na barra de ferramentas:
                        </p>
                        <p>
                            <b>No Chrome</b> Pressione o ícone de quebra-cabeça ao lado da barra de endereços. Depois, pressione o ícone de alfinete ao lado do nome da extensão.
                            <br /><b>No Edge</b> Pressione o ícone de quebra-cabeça ao lado da barra de endereços. Depois, pressione o ícone de 'olho' ao lado do nome da extensão.
                            <br /><b>No Firefox</b> Clique com o botão direito próximo à barra de endereços e escolha "Personalizar barra de ferramentas". Em seguida, arraste o ícone da extensão para a barra de ferramentas.
                        </p>
                    </div>
                    <div id="h-t-disable-extension" className="main-title">
                        <p>
                            Desativar extensão
                        </p>
                    </div>
                    <div className="article">        
                        <p>
                        Se os atalhos com o mouse ou a função de arrastar causarem problemas, como o bloqueio de menus pop-up, isso pode ser solucionado ao pressionar o botão Ctrl antes de utilizar o mouse.
                        </p>
                        <p>
                            Também é possível desativar a extensão para o site atual ou para todos os sites. Basta acessar <img class="h-t-img" src="../assets/icon128.png" alt="Power Mouse X icon" className="icon" /> na barra de ferramentas (ao lado da barra de endereços) e ajustar os interruptores. Caso o ícone não esteja visível, consulte a seção anterior.
                        </p>
                    </div>
                </div>
            </>
        )
}
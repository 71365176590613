import { sendPasswordResetEmail } from "firebase/auth";
//import '../Site.css'
//import '../User.css'
import { useState} from "react";
import CancelBtn from "./CancelBtn";

export default function PasswordRecovery(props){
    document.title = "Power Mouse X - Password Recovery";
    const [email, setEmail] = useState("");
    const [checked, setChecked] = useState(false);
    const [recoveryEmailSent , setRecoveryEmailSent] = useState(false);

    function emailPass(){
        if(email.indexOf('@')==-1){return false;}
        if(email.indexOf('@') > (email.length -3)){return false;}
        if(email.indexOf('@') == 0){return false;}
        return (email.indexOf('.')!=(-1));
    }

    const resetPass = async (event) =>{
        event.preventDefault();
        setChecked(true);
        if(emailPass()){
            try{
                await sendPasswordResetEmail(props.auth, email);
                setRecoveryEmailSent(true); 
            }catch(err){
                console.log(err);
            }
        }
    };

return(
    <div className="main-container">
    <div id="password-reset" className="modal">
    <form id="password-reset-form" className="modal-content" onSubmit={resetPass}>
        <div className="container">
            <h1>Password reset</h1>
            <br />
            <hr />
            <label htmlFor="email"><b>Email</b></label>
            {(checked && !emailPass())?<span className="issue"> - Email not vailed</span> : ""}
            <input type="text" placeholder="Enter Email" id="password-reset-email" name="email" required onChange={(e) => setEmail(e.target.value)}/>
            <br />
            <div className="clearfix">
                <CancelBtn />
                <button type="submit" id="password-reset-submit" className="password-reset">Send</button>
            </div>
            {recoveryEmailSent? <div> We Sent you a recovery email </div> : ""}
        </div>
    </form>
    </div>
    </div>
)

}
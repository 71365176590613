export default function DragOptionsTable(props){
    return(
<table id="h-t-options-table" style={{float:"right", marginLeft:"24px"}}>
                            <tr>
                                <td></td><td><b>{props.v[0]}</b></td><td><b>{props.v[1]}</b></td><td><b>{props.v[2]}</b></td>
                            </tr>
                            <tr>
                                <td><b>{props.h[0]}<sup>(1)</sup></b></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td>
                            </tr>
                            <tr>
                                <td><b>{props.h[1]}</b></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td>
                            </tr>
                            <tr>
                                <td><b>{props.h[2]}<sup>(2)</sup></b></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td><td></td><td></td>
                            </tr>
                            <tr>
                                <td><b>{props.h[3]}<sup>(2)</sup></b></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td><td></td><td></td>
                            </tr>
                            <tr>
                                <td><b>{props.h[4]}</b></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td><td></td><td></td>
                            </tr>
                            <tr>
                                <td><b>{props.h[5]}</b></td><td></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td>
                            </tr>
                            <tr>
                                <td><b>{props.h[6]}</b></td><td></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td>
                            </tr>
                            <tr>
                                <td><b>{props.h[7]}<sup>(3)</sup></b></td><td></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td>
                            </tr>
                            <tr>
                                <td><b>{props.h[8]}</b></td><td></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td>
                            </tr>                            
                            <tr>
                                <td><b>{props.h[9]}</b></td><td></td><td></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td>
                            </tr>
                        </table>
        )
    }
export default function PostContact(){
    
    document.title = "Power Mouse X - Contact";

    return (
        <div className="main-container">
            <h1>
                <p>Thank you for contacting us. We will be in touch with you very soon.</p>
                <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
            </h1>
        </div>
    )
}
import { HashLink as Link } from 'react-router-hash-link';
import LangAdvance from '../LangAdvance';

export default function HowTo(){
    document.title = "Power Mouse X - How To";
    return (
        <>
        <LangAdvance/>
            <div id="h-t-quick-nav">
                <b>
                    <ul style={{listStyleType:'none', margin: '0 0 0 0', padding: '0 0 0 0'}}>
                        <li><a href="#h-t-quick-search">Quick search bar</a></li>
                    </ul>
                </b>
            </div>
            <div className="main-container">
                <div id="h-t-languages"></div>
                <div className="page-header"><p>Learn how to use Power Mouse X</p></div>
        
                <div id="h-t-quick-search" className="main-title">
                    <p>Quick search bar</p>
                </div>
                <div className="article">
                The quick search bar allows you to instantly go to a site, search the web or search for a tab.
                To use the QSB just hold the right mouse button and start typing.
                    <div className="sub-title">
                        <p>Going to another site and search the web</p>
                    </div>
                    <p>
                        You can navigate to a site almost instantly by using the Quick search bar.
                    </p>        
                    Start typing the name of the site (don’t release the mouse button yet). The QSB will try to predict the site you are looking for and display it. Once the bar predicts the site correctly (usually after typing just a few characters) you can release the mouse button and you will be sent to the site.
                    <p>
                    <img class="h-t-img" src="../assets/quick-search-bar.gif" alt="search bar" />
                        <p>
                        You can use the Quick search bar to search the web.
                    Write a search query instead of a site name, any time the search bar can’t predict the site it will assume you want to search the web and show a search symbol <img class="h-t-img" src="../assets/search.svg" alt="search icon" className="icon" /> on its right. 
                    Once you release the mouse button you will go to the search query result page of a search engine.
                    People with paid subscription can choose the search engine.
                        </p>
                    </p>
                    <div className="sub-title">
                        <p>
                            Search a tab
                        </p>
                    </div>
                    <div>
                        If you have many tabs opened, the easiest way to find and switch to another tab may be using the Quick search bar.
                        Press Shift + Tab, You will see a <img class="h-t-img" src="../assets/leftRightArrow.svg" alt="search tab icon" className="icon" />  icon appearing on the bar indicating "search tab mode". If you wish to cancel, press Shift + Tab again.
                        You can open the QSB with this setting on by pressing Shift + Tab or "/" while holding the right mouse button. Using the "/" button may be easier for left handed people, but unlike pressing Shift + Tab, pressing "/" after the QSB has opened will not change the "search tab mode" status.
                    </div>
                    <div className="sub-title">
                        <p>
                            Typing with both hands
                        </p>
                    </div>
                    Typing with one hand can be uncomfortable, especially when you are writing a long search query.
                    To be able to type with both hands open the Quick search bar by holding the right mouse button and pressing the space bar. You can release the mouse button now and use both hands, a lock symbol on the bar <img class="h-t-img" src="../assets/lock.svg" alt="lock icon" className="icon" /> indicates that the bar will stay. Press the Enter key when you are done typing.
                    <div className="sub-title">
                        <p>
                            Opening a site in a new tab
                        </p>
                    </div>
                    <p>
                        If you want to go to a site or search the web in a new tab, press the Tab key.
                        You will see a plus symbol <img class="h-t-img" src="../assets/newTab.svg" alt="plus icon" className="icon" /> appearing on the bar indicating "New tab mode", meaning the results will be in a new tab. If you wish to cancel, press Tab again.
                    </p>
                    <p>
                        You can open the Quick search bar with this setting on by pressing Tab or "+" while holding the right mouse button. Using the "+" button may be easier for left handed people, but unlike the Tab button, pressing it after the Quick search bar has opened will not change the "New tab mode" status.
                    </p>
                    <div className="sub-title">
                        <p>
                            Multi-language support
                        </p>
                    </div>
                    <p>
                        If you have more than one keyboard layout, you probably found yourself many times typing using the wrong layout. Wouldn't it be nice if you could fix that with a press of a button? Go to the settings page and set your keyboard layouts, next time you will mistakenly type into the quick search bar using your 2nd layout press F12 and the text will magically transform to the correct text as if you were typing your 1st layout. Type F12 again to transform the text to the 2nd keyboard layout.
                    </p>
                    <p>
                        You can use up to four layouts, each F12 press will cause the extension to attempt to convert between layout 1 and the other layouts, at this order: 2&#8594;1, 3&#8594;1, 4&#8594;1, 1&#8594;2, 1&#8594;3, 1&#8594;4.
                    </p>
                    <div className="sub-title">
                        <p>
                            Changing your mind
                        </p>
                    </div>
                    At any point you can cancel the quick search bar by pressing the Escape button.
                    </div>
                </div>                                
            </>
        )
}
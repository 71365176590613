import { doc, setDoc, getFirestore } from "firebase/firestore";

//import { collection, addDoc, serverTimestamp } from "firebase/firestore"; 

export default function BuyPlan(props){
    
    document.title = "Power Mouse X - Buy Plan";
    if(props.user){
        let duration = 2678400000;
        if(props.plan=="trimountly"){
            duration*=3;
        }else if(props.plan=="quad mountly"){
            duration*=4;
        }
        setDoc(doc(props.db, "users", props.user.uid), {
            plans:{
                0:{
                    name: props.plan,
                    start: Date.now(),
                    duration: duration,
                    autoRenew: true,
                    uid0:"",
                    uid1:"",
                    uid2:"",
                    uid3:"",
                    uid4:"",
                    uid5:""
                }
            }
        }, { merge: true });
    }
    return (
        <div className="main-container">
            <h1>
                <p>This is still in beta and is provided tamporaly for free.</p>
                <p>The plan was added to your user. Go to your user's plan page to add your browser.</p>
                <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
            </h1>
        </div>
    )
}
